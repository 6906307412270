import React from 'react';

import { ReactComponent as ChainIcon } from './../../../../assets/icons/landing-chain.svg';
import { ReactComponent as StarsIcon } from './../../../../assets/icons/stars.svg';

const AnaliticsBlock = () => {
  return (
    <div className="w-full h-full flex flex-col mt-[-14px] px-8 md:px-[76px]">
      <div className="flex flex-row">
        <div className="relative w-1/2 h-[290px] flex items-end border-r rounded-[20px]">
          <ChainIcon className="absolute left-[120px] bottom-[-35px] w-[70px] h-[70px] z-10" />
          <StarsIcon className="absolute bottom-0 left-0 max-h-[120px] w-fit z-0" />
        </div>
        <div className="w-1/2"></div>
      </div>
      <div id="analytics" className="relative w-full h-fit">
        <div className="max-w-[49.3%] h-[400px] md:h-[300px] flex items-center mt-[-1px] pl-4 md:pl-[100px] py-[60px] border-l border-t border-b rounded-l-[20px]">
          <h4 className="absolute max-w-[1000px] text-white text-[50px] leading-[100%] font-semibold">
            Глубокая <span className="lending-text-gradient">аналитика</span> для <br /> достижения
            высоких результатов
          </h4>
        </div>
      </div>
    </div>
  );
};

export default AnaliticsBlock;
