import React from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import { ReactComponent as EditIcon } from 'assets/icons/pen.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { Answer } from 'modules/coach/views/test-create/Answer';
import { useNotification } from 'hooks/notification';

import { Question } from '../../views/test-create';

interface Props {
  dragProps: DraggableProvidedDragHandleProps | null | undefined;
  number: number;
  question: Question;
  onDelete: (id: number) => void;
  onCopy: (question: Question) => void;
  onChange: (question: Question) => void;
}

const TestQuestionCard: React.FC<Props> = ({
  dragProps,
  number,
  question,
  onCopy,
  onDelete,
  onChange,
}) => {
  const notify = useNotification();

  const addNewAnswer = () => {
    const answer: Answer = {
      id: Date.now(),
      name: '',
      isCorrect: false,
    };

    onChange({
      ...question,
      answers: [...question.answers, answer],
    });
  };

  const onQuestionTitleChange = (val: string) => {
    onChange({
      ...question,
      name: val,
    });
  };

  const onAnswerTitleChange = (id: number, value: string) => {
    onChange({
      ...question,
      answers: question.answers.map((a) => (a.id === id ? { ...a, name: value } : a)),
    });
  };

  const onRemoveAnswer = (id: number) => {
    onChange({
      ...question,
      answers: question.answers.filter((a) => a.id !== id),
    });
  };

  const toggleAsCorrectAnswer = (id: number) => {
    onChange({
      ...question,
      answers: question.answers.map((a) => (a.id === id ? { ...a, isCorrect: !a.isCorrect } : a)),
    });
  };

  const onQuestionExpand = () => {
    onChange({
      ...question,
      isExpand: !question.isExpand,
    });
  };

  const onEdit = () => {
    console.warn('onEdit');
    onChange({
      ...question,
      done: false,
    });
  };

  const onAnswerOrderChange = (result: DropResult) => {
    if (!result.destination) return;

    const newAnswers = [...question.answers];
    const [reorderedAnswer] = newAnswers.splice(result.source.index, 1);
    newAnswers.splice(result.destination.index, 0, reorderedAnswer);
    onChange({
      ...question,
      answers: newAnswers,
    });
  };

  const onQuestionDone = () => {
    if (!question.name) {
      notify.error('Вопрос обязателен для заполнения');
      return;
    }

    if (question.answers.length < 2) {
      notify.error('У теста должен быть минимум 2 ответа');
      return;
    }

    if (!question.answers.some((a) => a.isCorrect)) {
      notify.error('У теста должен быть минимум один правильный ответ');
      return;
    }

    onChange({
      ...question,
      done: true,
    });
  };

  return (
    <div className="mb-7 rounded-2xl theme-student-card">
      <div className="relative flex flex-col md:flex-row md:items-center md:space-x-3 space-y-4 md:space-y-0 p-4 md:p-7">
        <div className="flex items-center space-x-4 pr-5 md:border-r border-white/10">
          <button {...dragProps}>
            <DragIcon />
          </button>
          <span className="flex items-center justify-center shrink-0 w-8 h-8 rounded-full bg-white border theme-border--active text-black text-sm">
            {number}
          </span>
        </div>
        <div className="flex items-center flex-1 space-x-5">
          {question.done ? (
            <div className="flex-1 flex items-center justify-between space-x-3">
              <p className="text-base md:text-xl">{question.name}</p>

              {question.done ? (
                <div className="absolute top-3 left-20 md:static flex items-center space-x-3">
                  <button
                    onClick={() => onEdit()}
                    className="flex items-center justify-center w-10 h-10 rounded bg-white/20"
                  >
                    <EditIcon className="w-6 h-6" />
                  </button>
                  <button
                    className="flex items-center justify-center w-10 h-10 rounded bg-white/20"
                    onClick={() => onCopy(question)}
                  >
                    <CopyIcon className="w-6 h-6" />
                  </button>
                  <button
                    className="flex items-center justify-center w-10 h-10 rounded bg-white/20"
                    onClick={() => onDelete(question.id)}
                  >
                    <TrashIcon className="w-5 h-5" />
                  </button>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="flex-1 px-3 py-2.5 border border-white/20 rounded">
              <input
                value={question.name}
                placeholder="Вопрос"
                className="w-full theme-input border pl-4 rounded-[12px] outline-0 text-xl"
                onChange={(event) => onQuestionTitleChange(event.target.value)}
              />
            </div>
          )}

          <button className="shrink-0" onClick={onQuestionExpand}>
            <ArrowDownIcon />
          </button>
        </div>
      </div>
      {question.answers.length && question.isExpand ? (
        <div className="px-4 md:px-7 py-5 border-t border-white/20">
          {question.done ? (
            question.answers.map((answer) => (
              <div key={answer.id} className="flex items-center justify-between py-2">
                <div className="flex-1">{answer.name}</div>
                <button
                  className={`flex items-center justify-center shrink-0 w-6 h-6 rounded ${
                    answer.isCorrect ? 'bg-[#58DB66]' : 'bg-white/10'
                  }`}
                >
                  <CheckIcon className="w-2.5 h-2.5" />
                </button>
              </div>
            ))
          ) : (
            <DragDropContext onDragEnd={onAnswerOrderChange}>
              <Droppable droppableId="answersList">
                {(outerProvided) => (
                  <div
                    {...outerProvided.droppableProps}
                    ref={outerProvided.innerRef}
                    className="flex flex-col space-y-5"
                  >
                    {question.answers.map((answer, index) => (
                      <Draggable key={answer.id} draggableId={answer.id.toString()} index={index}>
                        {(provided) => (
                          <div
                            key={answer.id}
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            className="flex items-center space-x-4"
                          >
                            <div className="flex items-center space-x-4 flex-1 p-1 border border-white/20 rounded">
                              <button {...provided.dragHandleProps}>
                                <DragIcon />
                              </button>
                              <div className="flex-1 px-4 border-l border-white/10">
                                <input
                                  value={answer.name}
                                  placeholder="Ответ"
                                  className="w-full theme-input border pl-4 rounded-[12px] outline-0 text-xl"
                                  onChange={(event) =>
                                    onAnswerTitleChange(answer.id, event.target.value)
                                  }
                                />
                              </div>
                              <button
                                className={`flex items-center justify-center shrink-0 w-6 h-6 rounded cursor-pointer ${
                                  answer.isCorrect
                                    ? 'bg-[#58DB66]'
                                    : 'bg-white/10 border theme-border--light-black'
                                }`}
                                onClick={() => toggleAsCorrectAnswer(answer.id)}
                              >
                                <CheckIcon className="w-2.5 h-2.5" />
                              </button>
                            </div>
                            <button onClick={() => onRemoveAnswer(answer.id)}>
                              <TrashIcon />
                            </button>
                          </div>
                        )}
                      </Draggable>
                    ))}

                    {outerProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      ) : null}

      {question.done ? null : (
        <div className="flex flex-col md:flex-row items-center justify-between space-y-2.5 md:space-y-0 py-4 px-7 border-t border-white/20">
          <button className="text-blue uppercase" onClick={addNewAnswer}>
            Добавить ответ
          </button>

          <div className="flex items-center space-x-3">
            <button className="py-2 px-7 rounded bg-blue text-white" onClick={onQuestionDone}>
              Готово
            </button>
            <button className="flex items-center justify-center w-10 h-10 rounded bg-white/20 hidden">
              <EditIcon className="w-6 h-6" />
            </button>
            <button
              className="flex items-center justify-center w-10 h-10 rounded bg-white/20"
              onClick={() => onCopy(question)}
            >
              <CopyIcon className="w-6 h-6" />
            </button>
            <button
              className="flex items-center justify-center w-10 h-10 rounded bg-white/20"
              onClick={() => onDelete(question.id)}
            >
              <TrashIcon className="w-5 h-5" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TestQuestionCard;
