import { FC, useState } from 'react';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';

import ButtonGroup from './ButtonGroup';

// Как будут апишки вынесется в репозиторий
interface Test {
  id: number;
  name: string;
}

interface Homework {
  id: number;
  name: string;
}

interface Lesson {
  id: number;
  name: string;
  homeworks: Homework[];
  tests: Test[];
}

interface Module {
  id: number;
  moduleName: string;
  lessons: Lesson[];
}

type Props = {
  modules: Module[];
};

const Modules: FC<Props> = (props) => {
  const [openModules, setOpenModules] = useState<{ [key: number]: boolean }>({});
  const [openLessons, setOpenLessons] = useState<{
    [moduleId: number]: { [lessonId: number]: boolean };
  }>({});

  const isFirstItem = (index: number) => index === 0;

  const handleModulesDropdown = (id: number) => {
    setOpenModules((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleLessonsDropdown = (moduleId: number, lessonId: number) => {
    setOpenLessons((prevState) => ({
      ...prevState,
      [moduleId]: {
        ...(prevState[moduleId] || {}),
        [lessonId]: !prevState[moduleId]?.[lessonId],
      },
    }));
  };

  return (
    <div className="w-full h-full flex flex-col gap-3">
      {props.modules &&
        props.modules.map((el, idx) => (
          <div key={el.id} className="flex flex-col">
            <div
              className={`w-full h-fit flex flex-row justify-between items-center p-6 bg-[#0c1231] rounded-xl ${
                openModules[el.id] ? 'rounded-b-none' : ''
              } ${isFirstItem(idx) ? 'first:rounded-tl-none' : ''}`}
            >
              <div className="flex flex-col gap-3">
                <span className="text-[#26A7EF] text-xs">Модуль {idx + 1}</span>
                <span className="text-white text-base font-semibold">{el.moduleName}</span>
              </div>

              <ButtonGroup
                isOpenDropdown={openModules[el.id] || false}
                onDropdownClick={() => handleModulesDropdown(el.id)}
              />
            </div>
            {openModules[el.id] && (
              <div className="p-6 bg-transparent border-b border-l border-r">
                {el.lessons.map((lesson, idx) => (
                  <div key={lesson.id} className="flex flex-row justify-between items-center">
                    <div className="flex flex-col gap-3">
                      <span className="text-white text-xs">Урок {idx + 1}</span>
                      <span className="text-white text-base font-semibold">{lesson.name}</span>
                    </div>

                    <ButtonGroup
                      isOpenDropdown={openLessons[el.id]?.[lesson.id] || false}
                      onDropdownClick={() => handleLessonsDropdown(el.id, lesson.id)}
                    />
                  </div>
                ))}
              </div>
            )}
            <div
              className={`${
                openModules[el.id] ? 'flex' : 'hidden'
              } w-full p-6 border-b border-l border-r rounded-b-xl`}
            >
              <button
                type="button"
                className="w-fit h-fit flex flex-row items-center gap-3 ml-auto px-5 py-3 text-white text-base border border-white rounded-full"
              >
                <AddIcon />
                Создать
              </button>
            </div>
          </div>
        ))}

      <button
        type="button"
        className="w-fit h-fit flex flex-row items-center gap-3 mt-9 px-5 py-3 text-white text-base border border-white rounded-full"
      >
        <AddIcon />
        Создать модуль
      </button>
    </div>
  );
};

export default Modules;
