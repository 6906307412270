import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as BellIcon } from 'assets/icons/bell.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import { ReactComponent as DiagramIcon } from 'assets/icons/diagram.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as PaperIcon } from 'assets/icons/paper.svg';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import Avatar from 'components/Avatar';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useWindowSize } from 'hooks/window-size';

import { authSlice } from '../../../../store/auth';

interface Props {
  isOpen: boolean;
  onClose: (val: boolean) => void;
}

const ProfileSidebar: React.FC<Props> = ({ isOpen, onClose }) => {
  const device = useWindowSize();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);

  const menu = [
    {
      id: 1,
      text: 'Редактировать профиль',
      path: '/student/profile',
      icon: <PenIcon />,
    },
    {
      id: 2,
      text: 'Пароль',
      path: '/student/profile/password',
      icon: <LockIcon />,
    },
    // {
    //   id: 3,
    //   text: 'Уведомления',
    //   path: '/student/profile/notifications',
    //   icon: <BellIcon />,
    // },
    // {
    //   id: 4,
    //   text: 'Материалы',
    //   path: '/student/profile/materials',
    //   icon: <PaperIcon />,
    // },
    // {
    //   id: 5,
    //   text: 'Статистика',
    //   path: '/student/profile/statistics',
    //   icon: <DiagramIcon />,
    // },
  ];

  const sidebarClass = useMemo(() => {
    if (device === 'desktop') {
      return 'w-80 h-full rounded-2xl border border-white/30 overflow-hidden';
    }

    if (isOpen) {
      return `
        fixed bottom-0 right-0 top-[90px] left-0 z-10 bg-[#ffffff40] flex justify-end
      `;
    }

    return 'hidden';
  }, [device, isOpen]);

  const logout = () => {
    dispatch(authSlice.actions.logout());
  };

  const toggleDropdown = () => {
    onClose(false);
  };

  return (
    <div className={sidebarClass} onClick={() => onClose(false)}>
      <div
        className="w-full sm:w-80 h-full theme-student-card overflow-y-auto"
        onClick={(event) => event.stopPropagation()}
      >
        <NavLink
          to="/student"
          className="hidden lg:block py-7 text-3xl text-current text-center font-semibold border-b theme-border--light-grey"
        >
          {user?.business?.name ? <span>{user?.business?.name}</span> : 'CTE PLATFORM'}
        </NavLink>

        <div className="flex flex-row lg:flex-col items-center justify-between space-x-4 lg:space-x-0 pt-7 pb-5 px-6 border-b theme-border--light-grey">
          <div className="flex items-center justify-center w-[90px] h-[90px] 2xl:w-[120px] 2xl:h-[120px] mb-0 lg:mb-5">
            <Avatar image={user?.avatar} />
          </div>

          <div className="flex-1">
            <p className="text-left lg:text-center truncate">{user?.fio}</p>
            <p className="block lg:hidden text-xs theme-text--active">Профиль</p>
            <p className="hidden lg:block mt-1 text-center text-sm theme-text--disabled">
              {user?.positionLevel}
            </p>
          </div>

          <ChevronRightIcon className="lg:hidden shrink-0" />
        </div>

        <div onClick={toggleDropdown}>
          {menu.map((item) => (
            <NavLink
              key={item.id}
              to={item.path}
              className={({ isActive }) => {
                return `flex items-center theme-text px-8 py-6 space-x-5 border-b theme-border--light-grey theme-icon-stroke-sidebar theme-text theme-background--hover ${
                  isActive && 'theme-background--grey'
                }`;
              }}
              end
            >
              {item.icon}
              <span className="theme-text--default">{item.text}</span>
            </NavLink>
          ))}

          <button
            className="flex items-center w-full mt-20 text-current px-8 py-6 space-x-5 border-t theme-border--light-grey theme-icon-stroke-sidebar"
            onClick={logout}
          >
            <LogoutIcon />
            <span>Выйти из аккаунта</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSidebar;
