import { ICourses, ICoursesGroups } from 'modules/coach/entities/coach-courses/Courses';
import request from 'api/axios-instance';

import { ICoursesDao, ICoursesGroupsDao } from '../../dao/courses/GetCourses.dao';

export const fetchCourses = (isActive: number, perPage: number, page: number) =>
  request
    .get(
      `v2/coach/total-courses?by_this_coach=1&is_active=${isActive}&perPage=${perPage}&page=${page}`
    )
    .then((res) => {
      const courses: ICourses[] = res.data.data.map(mapCoursesDaoToEntity);

      return courses;
    });

function mapCoursesDaoToEntity(coursesDao: ICoursesDao): ICourses {
  return {
    id: coursesDao.id,
    name: coursesDao.name,
    image: coursesDao.image,
    dateFrom: coursesDao.date_from,
    dateTo: coursesDao.date_to,
    groups: mapCoursesGroupsDaoToEntity(coursesDao.groups),
  };
}

function mapCoursesGroupsDaoToEntity(groupsDao: ICoursesGroupsDao[]): ICoursesGroups[] {
  return groupsDao.map((group) => ({
    id: group.id,
    groupName: group.group_name,
    courseId: group.course_id,
  }));
}
