import { useEffect, useState } from 'react';
import LMSPagination from 'components/LMSPagination';
import Loader from 'components/Loader';
import { fetchCourses } from 'modules/coach/api/repository/courses/get-courses';
import CourseCard from 'modules/coach/components/courses/CourseCard';
import CourseGroupTable from 'modules/coach/components/courses/CourseGroupTable';
import SwitchTabs from 'modules/coach/components/courses/SwitchTabs';
import { ICourses } from 'modules/coach/entities/coach-courses/Courses';
import { useNotification } from 'hooks/notification';

const CoachCoursesMain = () => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [courses, setCourses] = useState<ICourses[]>([]);
  const [isLoading, setLoading] = useState(true);
  const notify = useNotification();

  useEffect(() => {
    fetchCourses(activeTab, 10, 1)
      .then((res) => setCourses(res))
      .catch(() => notify.error('Произошла ошибка. Обратитесь в службу поддержки'))
      .finally(() => setLoading(false));
  }, [activeTab]);

  const switchActiveTab = (tab: number) => {
    setActiveTab(tab);
  };

  return (
    <div className="h-screen flex flex-col gap-16 pt-24">
      <SwitchTabs activeTab={Number(activeTab)} onTabChange={(value) => switchActiveTab(value)} />

      <div className="flex flex-col gap-5">
        {isLoading ? (
          <Loader />
        ) : courses.length === 0 ? (
          <p className="text-white text-xl">Курсы не найдены</p>
        ) : (
          courses.map((course) => (
            <CourseCard key={course.id} count={3} title={course.name} img={course.image}>
              <CourseGroupTable
                groups={course.groups}
                startDate={course.dateFrom}
                endDate={course.dateTo}
              />
            </CourseCard>
          ))
        )}
      </div>

      <div className="w-full flex justify-start items-center mt-auto py-10 border-t border-white border-opacity-40">
        <LMSPagination current={1} lastPage={5} onChange={() => {}} />
      </div>
    </div>
  );
};

export default CoachCoursesMain;
