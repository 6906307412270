import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import Loader from 'components/Loader';
import { useNotification } from 'hooks/notification';

import { fetchAttendance } from '../../api/repository/journal';
import AttendanceTable from '../../components/AttendanceTable';
import JournalFilter from '../../components/JournalFilter';

type TAttendance = {
  attendance: string;
  date: string;
};

interface Attendance {
  id: number;
  full_name: string;
  avatar: string;
  attendance: TAttendance[];
}

const HRJournal = () => {
  const routeParams = useParams<string>();
  const [selectedCourse, setSelectedCourse] = useState<number>();
  const [selectedGroup, setSelectedGroup] = useState<number>();
  const [isLoading, setLoading] = useState(false);
  const [attendance, setAttendance] = useState<Attendance[]>([]);
  const [range, selectRange] = useState<string[]>([]);
  const [count, setCount] = useState<number>(0);
  const [currentRange, setCurrentRange] = useState<{
    start: string | null;
    end: string | null;
  }>({
    start: null,
    end: null,
  });
  const notify = useNotification();
  const calendarRef = useRef<FullCalendar>(null) as React.RefObject<FullCalendar>;

  useEffect(() => {
    changeDates(calendarRef?.current?.getApi());
  }, []);

  useEffect(() => {
    setLoading(true);

    const calendar = calendarRef.current?.getApi();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const start = calendar?.currentData?.dateProfile.currentRange.start;

    const params = {
      course_id: routeParams?.course_id || selectedCourse,
      group_id: routeParams?.group_id || selectedGroup,
      date_from: currentRange.start || start,
      date_to: currentRange.end,
    };

    fetchAttendance(params)
      .then((res) => {
        setAttendance(res.data);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [count, selectedCourse, selectedGroup]);

  function handleFilterChange({ courseId, groupId }: { courseId: number; groupId: number }) {
    setSelectedCourse(courseId);
    setSelectedGroup(groupId);
  }

  const formatDate = (date: Date) => {
    const year = date.getFullYear().toString().padStart(4, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const changeDates = (el: unknown): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const lastDate = el?.currentData?.dateProfile.currentRange.end;
    const prevLastDate = new Date(lastDate.getTime() - 86400000);

    setCurrentRange({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      start: formatDate(el?.currentData?.dateProfile.currentRange.start),
      end: formatDate(prevLastDate),
    });

    const newRange = [];

    for (let i = 0; i <= 7; i++) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const firstDay = new Date(el?.currentData?.dateProfile.currentRange.start);
      const followingDay = new Date(firstDay.getTime() + 86400000 * i); // + 1 day in ms
      newRange.push(formatDate(followingDay));
    }

    selectRange(newRange);
  };

  return (
    <div className="w-full flex flex-col">
      <h2 className="text-[32px] font-semibold mb-6 lg:mb-10">Журнал посещаемости</h2>

      <div className="flex flex-col items-start">
        <div className="hidden md:flex mb-10">
          <JournalFilter filterParams={handleFilterChange} />
        </div>

        <div className="flex-1 rounded-2xl bg-studentCardBg w-full">
          <div className="journal-hr-calender border-b w-full border-white/20 flex flex-row-reverse">
            <div className="w-full max-w-[1000px]">
              <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin]}
                initialView="dayGridWeek"
                firstDay={1}
                customButtons={{
                  myCustomButtonLeft: {
                    icon: 'chevron-left',
                    click: function () {
                      const calendarApi = calendarRef.current?.getApi();
                      calendarApi?.prev();

                      changeDates(calendarApi);

                      setCount(count + 1);
                    },
                  },
                  myCustomButtonRight: {
                    icon: 'chevron-right',
                    click: function () {
                      const calendarApi = calendarRef.current?.getApi();
                      calendarApi?.next();

                      selectRange([]);

                      changeDates(calendarApi);

                      setCount(count + 1);
                    },
                  },
                  myCustomButtonToday: {
                    text: 'Сегодня',
                    click: function () {
                      const calendarApi = calendarRef.current?.getApi();
                      calendarApi?.today();

                      selectRange([]);

                      changeDates(calendarApi);

                      setCount(count + 1);
                    },
                  },
                }}
                headerToolbar={{
                  left: 'myCustomButtonLeft',
                  center: 'title',
                  right: 'myCustomButtonRight, myCustomButtonToday',
                }}
                titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }}
                contentHeight="auto"
                locale="ru"
                selectable
                dayHeaderContent={(arg) => {
                  return (
                    <div className={`${'text-white'} px-4 py-2 flex flex-col items-center`}>
                      <p className={`${'text-white/30'} text-xs text-left`}>
                        {arg.text.split(' ')[0]}
                      </p>
                      <p className="text-lg md:text-[22px] font-medium text-left">
                        {arg.date.getDate()}
                      </p>
                    </div>
                  );
                }}
              />
            </div>
            <div className="self-end min-w-[340px]">
              <div className="flex flex-row gap-10 px-7 py-1 text-white pb-4 text-white/50 text-medium">
                <span>№</span>
                <span>Студент</span>
              </div>
            </div>
          </div>
          <div>
            {isLoading && (
              <div className="w-full h-40 flex flex-row justify-center items-center">
                <Loader />
              </div>
            )}
            {attendance.length > 0 && !isLoading && (
              <div>
                {attendance?.map((student, index) => (
                  <div key={student?.id} className="flex flex-col gap-8 bg-studentCardBg">
                    <AttendanceTable student={student} index={index} rangeDays={range} />
                  </div>
                ))}
              </div>
            )}{' '}
            {!attendance.length && !isLoading && <p className="text-center py-10">Список пуст</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HRJournal;
