import React from 'react';
import { IHomeWorkForCheck } from 'modules/coach/entities/HomeWorkForCheck';

interface Props {
  tests: IHomeWorkForCheck[];
}

const TestsTable: React.FC<Props> = ({ tests }) => {
  return (
    <table className="theme-background-default-table w-full rounded-[20px] backdrop-blur-[60px] min-w-[980px] text-current overflow-x-scroll">
      <thead className="block px-8 pt-4 pb-[10px] w-full theme-background-default-table">
        <tr className="grid grid-cols-coachTests w-full gap-x-8">
          <td>ФИО</td>
          <td>Название курса</td>
          <td>Группа</td>
          <td>Название теста</td>
          <td>Балл</td>
          <td>Кол-во попыток</td>
          <td>Затраченное время на тест</td>
        </tr>
      </thead>

      <tbody>
        {tests.map((test) => (
          <tr
            className="grid grid-cols-coachTests w-full px-8 pt-4 pb-[10px] gap-x-8 border-t theme-border--light-black hover:text-blue transition cursor-pointer"
            key={test.id}
          >
            <td className="w-full flex items-center">
              <span className="w-full max-w-full truncate">{test.studentName}</span>
            </td>
            <td className="w-full flex items-center">
              <span className="w-full max-w-full truncate">{test.courseName}</span>
            </td>
            <td className="w-full flex items-center">
              <span className="w-full max-w-full truncate">{test.groupName}</span>
            </td>
            <td className="w-full flex items-center">
              <span className="w-full max-w-full truncate">{test.name}</span>
            </td>
            <td className="w-full flex items-center">
              <span className="w-full max-w-full truncate">{test.mark || '-'}</span>
            </td>
            <td className="w-full flex items-center">
              <span className="w-full max-w-full truncate">-</span>
            </td>
            <td className="w-full flex items-center">
              <span className="w-full max-w-full truncate">-</span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TestsTable;
