import { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import { ReactComponent as EditIcon } from 'assets/icons/couch/cedit.svg';
import { ReactComponent as DocIcon } from 'assets/icons/file.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import LMSButton from 'components/LMSButton';
import LMSTitle from 'components/LMSTitle';
import Loader from 'components/Loader';
import { deleteHomework, fetchHomework } from 'modules/coach/api/repository/homeworks';
import { INewHomeWork } from 'modules/coach/entities/HomeWorks';
import { useConfirmBox } from 'hooks/confirm-box';
import { useNotification } from 'hooks/notification';

import FileDownloadButton from '../../components/DownLoadButton';

const HomeWork = () => {
  const { id } = useParams();
  const [homeTask, setHomeTask] = useState<INewHomeWork | null>(null);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const notify = useNotification();
  const { showConfirmBox } = useConfirmBox();

  useEffect(() => {
    fetchHomework(Number(id))
      .then((res) => setHomeTask(res))
      .catch(() => notify.error('Произошла ошибка. Обратитесь в службу поддержки'))
      .finally(() => setLoading(false));
  }, []);

  const onDeleteHomework = () => {
    showConfirmBox({
      message: 'Вы уверены, что хотите удалить домашнее задание?',
      okText: 'Да',
      cancelText: 'Нет',
      cancelVariant: 'red',
      onAccept: () => {
        deleteHomework(Number(id))
          .then(() => {
            notify.success('Домашнее задание успешно удалено');
            setTimeout(() => {
              goBack();
            }, 700);
          })
          .catch(() => notify.error('Произошла ошибка. Обратитесь в службу поддержки'));
      },
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="w-full h-full flex flex-col mt-24 lg:mt-4">
      <button
        type="button"
        onClick={() => goBack()}
        className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-current text-[14px] font-medium bg-white rounded-[36px] border-none outline-none uppercase"
      >
        <ArrowBack />
        назад
      </button>

      {isLoading ? (
        <div className="my-10">
          <Loader theme="dark" />
        </div>
      ) : (
        <div className="flex flex-col mt-10 space-y-10">
          <div className="flex flex-col justify-between gap-3 md:flex-row md:items-end">
            <LMSTitle tag="h4" className="flex-1 text-current text-3xl font-medium overflow-hidden">
              {homeTask?.name}
            </LMSTitle>

            <div className="flex flex-row gap-x-2">
              <LMSButton
                prefix={<EditIcon />}
                onClick={() => navigate(`/coach/homeworks/homework/edit/${id}`)}
              >
                РЕДАКТИРОВАТЬ
              </LMSButton>
              <button
                onClick={onDeleteHomework}
                className="w-[55px] h-[55px] flex justify-center items-center p-0 text-current bg-white theme-icon-stroke border theme-border--active cursor-pointer rounded-full"
              >
                <TrashIcon />
              </button>
            </div>
          </div>

          <div className="flex flex-col p-10 theme-student-card rounded-[40px] space-y-10 text-xl text-current">
            <p className="text-current text-3xl">Домашнее задание</p>
            <div
              className="h-fit student-description"
              dangerouslySetInnerHTML={{ __html: homeTask?.description || '' }}
            ></div>
          </div>

          <div className="w-full flex flex-col gap-5 mt-16">
            <section className="flex flex-row items-center gap-x-2 theme-icon-stroke">
              <h4 className="theme-text--default text-3xl font-medium">Материалы</h4>
              <DocIcon />
            </section>

            <div className="flex flex-wrap gap-4">
              {homeTask?.fileUrl?.length ? (
                homeTask?.fileUrl?.map((el, idx) => (
                  <div
                    key={idx}
                    className="w-[48%] flex flex-row justify-between items-center p-7 bg-gradient rounded-[14px]"
                  >
                    <div className="max-w-[400px] flex flex-col gap-2">
                      <p className="flex flex-row items-end gap-x-2 text-current text-ld">
                        {el.name}
                        <span className="text-current text-opacity-30 text-base">
                          {(Number(el.size) / (1024 * 1024)).toFixed(2)} мб
                        </span>
                      </p>
                    </div>
                    <FileDownloadButton fileLink={el.downloadLink} name={el.name} />
                  </div>
                ))
              ) : (
                <div className="flex flex-col mt-4 mx-auto text-xl text-center text-current text-opacity-25">
                  Материалы отсутствуют{' '}
                  <NavLink
                    to={`/coach/homeworks/homework/edit/${id}`}
                    className="hover:underline hover:underline-offset-4"
                  >
                    Добавить материал +
                  </NavLink>
                </div>
              )}
            </div>
          </div>

          <div className="w-full flex flex-col gap-5 mt-16">
            <section className="flex flex-row items-center gap-x-2 theme-icon-stroke">
              <h4 className=" theme-text--default text-3xl font-medium">Видео материалы</h4>
              <DocIcon />
            </section>
            <div className="flex flex-wrap gap-4">
              {homeTask?.videoUrl?.length ? (
                homeTask?.videoUrl?.map((el, idx) => (
                  <div
                    key={idx}
                    className="w-[48%] flex flex-row justify-between items-center p-7 bg-gradient rounded-[14px]"
                  >
                    <div className="max-w-[400px] flex flex-col gap-2">
                      <p className="flex flex-row items-end gap-x-2 text-current text-ld">
                        {el.name}
                        <span className="text-current text-opacity-30 text-base">
                          {(Number(el.size) / (1024 * 1024)).toFixed(2)} мб
                        </span>
                      </p>
                    </div>
                    <FileDownloadButton fileLink={el.downloadLink} name={el.name} />
                  </div>
                ))
              ) : (
                <div className="flex flex-col mt-4 mx-auto text-xl text-center text-current text-opacity-25">
                  Материалы отсутствуют{' '}
                  <NavLink
                    to={`/coach/homeworks/homework/edit/${id}`}
                    className="hover:underline hover:underline-offset-4"
                  >
                    Добавить материал +
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeWork;
