import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import ChangeRoleButton from 'components/ChangeRoleButton';
import SideBar from 'modules/hr/components/SideBar';
import SideBarMobile from 'modules/hr/components/SideBar/SideBarMobile';

import { useAppSelector } from '../hooks/redux';

const HrLayout = () => {
  const { user } = useAppSelector((state) => state.auth);

  if (!user) {
    return <Navigate to="/auth/login" />;
  }

  if (user.currentRole === 'coach') {
    return <Navigate to="/coach/schedule" />;
  }

  if (user.currentRole === 'student') {
    return <Navigate to="/student" />;
  }

  return (
    <div className="relative min-h-[100dvh] w-full bg-main bg-cover bg-no-repeat bg-fixed text-white">
      <div className="w-full flex flex-row">
        <div className="hidden fixed lg:inset-0 lg:h-[100vh] lg:block lg:w-[36%] lg:p-10 xl:w-[30%] 2xl:w-[26%]">
          <SideBar />
        </div>
        <div className="w-full relative z-10 lg:hidden">
          <SideBarMobile />
        </div>
        <div
          className="flex flex-col fixed right-0 w-full p-6 md:p-10 lg:w-[68%] xl:w-[73%] 2xl:w-[76%] overflow-scroll"
          style={{ maxHeight: '100vh' }}
        >
          <div className="hidden lg:block">
            <ChangeRoleButton />
          </div>
          <div className="mt-20 lg:mt-6">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HrLayout;
