import { useState } from 'react';
import { ReactComponent as SuccessIcon } from 'assets/icons/check.svg';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as NegativeIcon } from 'assets/icons/close.svg';
import LMSPagination from 'components/LMSPagination';

const CoachCourseStudentTests = () => {
  const [openTest, setOpenTest] = useState<{ [key: number]: boolean }>({});

  const handleDropdownClick = (index: number) => {
    setOpenTest((prevOpenTest) => ({
      ...prevOpenTest,
      [index]: !prevOpenTest[index],
    }));
  };

  const lessons = [
    {
      id: 0,
      testName: 'Чек-лист новичка',
      date: '22.10.2021',
      deadlineDate: '23.10.2021',
      status: true,
      evaluation: '90',
    },
    {
      id: 1,
      testName: 'Первый проект',
      date: '23.10.2021',
      deadlineDate: '24.10.2021',
      status: true,
      evaluation: '90',
    },
    {
      id: 2,
      testName: 'Договоренности и договор',
      date: '24.10.2021',
      deadlineDate: '25.10.2021',
      status: true,
      evaluation: '80',
    },
    {
      id: 3,
      testName: 'Бриф команды и работа в Trello',
      date: '25.10.2021',
      deadlineDate: '26.10.2021',
      status: true,
      evaluation: '70',
    },
    {
      id: 4,
      testName: 'Встречи и резюме',
      date: '',
      deadlineDate: '27.10.2021',
      status: false,
      evaluation: '',
    },
    {
      id: 5,
      testName: 'Как все всегда успевать',
      date: '27.10.2021',
      deadlineDate: '28.10.2021',
      status: true,
      evaluation: '',
    },
  ];

  const progressStyle = (val: number) => {
    const progressPercent = (val / 100) * 100;

    return {
      width: `${progressPercent}%`,
    };
  };

  return (
    <div className="flex flex-col gap-16 p-6 bg-[#0c1231] rounded-tr-[26px] rounded-b-[26px]">
      <div className="w-full h-full flex flex-col">
        <table className="hidden w-full table-auto md:table">
          <thead>
            <tr className="h-12 text-white text-sm font-normal text-opacity-40">
              <th className="w-[5%] text-left">№</th>
              <th className="w-[35%] text-left">Название теста</th>
              <th className="w-[10%] text-left">Дата сдачи</th>
              <th className="w-[10%] text-left">Дата дедлайна</th>
              <th className="w-[15%] text-left">Статус</th>
              <th className="w-[15%] text-left">Оценка</th>
            </tr>
          </thead>
          <tbody className="w-full">
            {lessons &&
              lessons.map((el) => (
                <tr key={el.id} className="w-full text-white text-base font-normal tracking-wide">
                  <td className="h-16 text-white text-lg">{el.id + 1}</td>
                  <td className="h-16 flex flex-row items-center gap-x-5 underline underline-offset-4">
                    {el.testName}
                  </td>
                  <td className="h-16">{el.date ? el.date : '-'}</td>
                  <td className="h-16">{el.deadlineDate ? el.deadlineDate : '-'}</td>
                  <td className="h-16">
                    {el.status ? (
                      <div className="flex flex-row items-center gap-2">
                        <SuccessIcon className="text-green" />
                        Сдано
                      </div>
                    ) : (
                      <div className="flex flex-row items-center gap-2">
                        <NegativeIcon className="w-6 text-[#FF5449]" />
                        Не сдано
                      </div>
                    )}
                  </td>
                  <td className="h-16">{el.evaluation ? `${el.evaluation} из 100` : '-'}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex flex-col md:hidden">
          {lessons &&
            lessons.map((el, idx) => (
              <div key={el.id}>
                <div
                  onClick={() => handleDropdownClick(idx)}
                  className="w-full py-3 text-white text-base font-normal border-t border-white border-opacity-10 cursor-pointer"
                >
                  <div className="flex flex-row gap-1 text-xs">
                    №<span>{idx + 1}</span>
                  </div>
                  <div className="w-full flex items-baseline justify-between text-lg">
                    {el.testName}
                    <DropdownIcon className={`${openTest[idx] ? 'rotate-180' : ''}`} />
                  </div>
                </div>
                {openTest[idx] && (
                  <div className="w-full py-3">
                    <div className="w-full flex flex-col">
                      <span className="text-white text-opacity-40">Оценка</span>
                      <span className="text-white pb-4">
                        {el.evaluation ? `${el.evaluation} из 100` : '-'}
                      </span>
                      {el.evaluation && (
                        <div className="relative w-full h-[2px] bg-white bg-opacity-20">
                          <span
                            className={`absolute left-0 top-0 bottom-0 bg-[#58DB66]`}
                            style={progressStyle(Number(el.evaluation))}
                          />
                        </div>
                      )}
                    </div>
                    <div className="w-full flex flex-row gap-x-4 py-4">
                      <div className="flex flex-col flex-1 text-white">
                        <span className="text-white text-opacity-20">Дата сдачи</span>
                        <span>{el.date}</span>
                      </div>
                      <div className="flex flex-col flex-1 text-white">
                        <span className="text-white text-opacity-20">Дата дедлайна</span>
                        <span>{el.deadlineDate}</span>
                      </div>
                      <div className="flex-1 text-white">
                        <span className="text-white text-opacity-20">Статус</span>
                        {el.status ? (
                          <div className="flex flex-row items-center gap-2">
                            <SuccessIcon className="text-green" />
                            Сдано
                          </div>
                        ) : (
                          <div className="flex flex-row items-center gap-2">
                            <NegativeIcon className="w-6 text-[#FF5449]" />
                            Не сдано
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>

      <div className="w-full flex justify-start items-center mt-auto pt-6 border-white border-t border-opacity-40 md:pt-10">
        <LMSPagination current={1} lastPage={5} onChange={() => {}} />
      </div>
    </div>
  );
};

export default CoachCourseStudentTests;
