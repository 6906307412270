import React, { useState } from 'react';

import { ReactComponent as HeartIcon } from './../../assets/icons/heart-checkmark.svg';
import { ReactComponent as LandigCircleIcon } from './../../assets/icons/landing-circlee3.svg';
// import { ReactComponent as Nout } from './../../assets/icons/landing-nout.svg';
import { ReactComponent as PeoplesIcon } from './../../assets/icons/Peoples.svg';
import { ReactComponent as ProductIcon } from './../../assets/icons/product-icon.svg';
import { ReactComponent as RocketIcon } from './../../assets/icons/rocket-landing.svg';
import { ReactComponent as SquareIcon } from './../../assets/icons/Square.svg';
import { ReactComponent as StarsIcon } from './../../assets/icons/stars.svg';
import AnaliticsBlock from './components/AnaliticsBlock';
import ChoisePlatform from './components/ChoisePlatform';
import LandingHeader from './components/header';
import LandingHero from './components/hero';
import MobileAppBlock from './components/MobileAppBlock';

const Landing = () => {
  const [currentTheme, setCurrentTheme] = useState<string>('dark');

  const toggleTheme = () => {
    if (currentTheme === 'dark') {
      setCurrentTheme('white');
      localStorage.setItem('theme', currentTheme);
    } else {
      setCurrentTheme('dark');
      localStorage.setItem('theme', currentTheme);
    }
  };

  return (
    <div className="min-h-screen w-full flex flex-col bg-[#05051E]">
      <LandingHeader />
      <LandingHero />

      <div className="w-full h-fit flex flex-col items-center gap-y-[44px] px-[20px] pb-[90px] lg:px-0">
        <div className="relative w-fit h-fit mx-auto">
          <StarsIcon className="max-w-[300px] md:max-w-[400px] lg:w-full" />
          <ProductIcon className="absolute bottom-0 left-[calc(50%-50px)] w-[100px] h-[100px]" />
        </div>
        <h4 className="max-w-[1080px] text-4xl text-[#CFD1E4] font-semibold lg:text-5xl">
          Наш продукт - это{' '}
          <span className="lending-text-gradient">мощный и гибкий инструмент,</span>
          созданный для эффективной организации{' '}
          <span className="lending-text-gradient">образовательных и тренировочных процессов.</span>
        </h4>
        <div className="max-w-[1100px] flex flex-col flex-wrap md:flex-row gap-16 mt-10 justify-center">
          <img
            className="h-[700px] w-[500px]"
            src={require('assets/images/product-2.png')}
            alt=""
          />
          <img
            className="h-[700px] w-[500px]"
            src={require('assets/images/integration-card.png')}
            alt=""
          />
          <img
            className="h-[700px] w-[500px]"
            src={require('assets/images/product-1.png')}
            alt=""
          />
          <img
            className="h-[700px] w-[500px]"
            src={require('assets/images/product-3.png')}
            alt=""
          />
        </div>
        {/* <Nout className="max-w-[700px] h-fit mt-10" /> */}
      </div>

      <ChoisePlatform />
      <MobileAppBlock />
      <AnaliticsBlock />

      {/* Interface personalization block */}
      <div
        id="interface"
        className="w-full h-fit flex flex-col items-center gap-y-[44px] px-[20px] pb-[90px] lg:px-0"
      >
        <div className="relative w-fit h-fit mx-auto">
          <StarsIcon className="max-w-[300px] md:max-w-[400px] lg:w-full" />
          <LandigCircleIcon className="absolute bottom-0 left-[calc(50%-50px)] w-[100px] h-[100px]" />
        </div>
        <h4 className="max-w-[700px] text-center text-4xl text-[#CFD1E4] font-semibold lg:text-5xl">
          Персонализация интерфейса <span className="lending-text-gradient">под ваш стиль</span>
        </h4>
        <p className="max-w-[740px] text-xl text-[#CFD1E4] font-light">
          Изменяйте цветовую палитру, загружайте собственный логотип и создавайте уникальное
          пространство, которое отражает вашу фирменную идентичность.
        </p>
        <div className="max-w-[800px] flex flex-col gap-5 md:flex-row">
          <div className="flex flex-col p-6 rounded-[16px] border border-white border-opacity-30">
            <HeartIcon />
            <span className="mt-6 text-white text-base font-semibold">Восприятие бренда</span>
            <p className="pt-2 text-white text-opacity-50 text-sm font-light">
              Пользователи будут ассоциировать продукт с вашим брендом, что создаст положительное
              впечатление и способствует лояльности.
            </p>
          </div>
          <div className="flex flex-col p-6 rounded-[16px] border border-white border-opacity-30">
            <PeoplesIcon />
            <span className="mt-6 text-white text-base font-semibold">Адаптация под аудиторию</span>
            <p className="pt-2 text-white text-opacity-50 text-sm font-light">
              Студенты и преподаватели могут легко общаться, задавать вопросы и получать ответы
              через наше приложение.
            </p>
          </div>
          <div className="flex flex-col p-6 rounded-[16px] border border-white border-opacity-30">
            <SquareIcon />
            <span className="mt-6 text-white text-base font-semibold">Корпоративный стиль</span>
            <p className="pt-2 text-white text-opacity-50 text-sm font-light">
              Персонализация интерфейса позволяет интегрировать корпоративный стиль вашей компании в
              дизайн продукта.
            </p>
          </div>
        </div>
        {/* <Nout className="max-w-[700px] h-fit mt-10" /> */}
      </div>

      {/* <div className="w-full h-full flex flex-col justify-center items-center">
        <div className="flex flex-row items-center gap-x-10 pb-[50px]">
          <span
            className={`${
              currentTheme === 'white' ? 'text-white text-opacity-20' : 'text-white'
            } select-none`}
          >
            Темная тема
          </span>
          <button
            onClick={() => toggleTheme()}
            className={`${
              currentTheme === 'dark' ? 'flex-row' : 'flex-row-reverse'
            } w-[100px] h-[50px] flex p-1.5 bg-[#37374B] rounded-full`}
          >
            <div className="h-full w-[40px] bg-white rounded-full"></div>
          </button>
          <span
            className={`${
              currentTheme === 'dark' ? 'text-white text-opacity-20' : 'text-white'
            } select-none`}
          >
            Светлая тема
          </span>
        </div>
      </div> */}
      {/* End interface personalization block */}
      <div className="w-full flex flex-col justify-center items-center mt-auto">
        <h4 className="max-w-[660px] py-20 text-center text-white text-5xl font-medium">
          Хотите эффективно управлять обучением?
        </h4>
        {/* <div className="p-1 mb-20 border border-white border-opacity-20 rounded-[55px]"> */}
        {/* <div className="p-1 border border-white border-opacity-20 rounded-[55px]"> */}
        {/* <button
              type="button"
              className="py-3 px-6 text-white font-bold text-lg bg-buttonBg rounded-[55px]"
            >
              ПОПРОБОВАТЬ БЕСПЛАТНО
            </button> */}
        {/* </div> */}
        {/* </div> */}
        <footer className="w-full py-8 border-white border-t border-opacity-20 md:px-10 lg:px-24">
          <div className="container">
            <div className="flex flex-col items-center md:justify-between md:flex-row">
              <div className="text-3xl text-white font-semibold select-none">CTE PLATFORM</div>
              <button className="text-white text-opacity-30 underline underline-offset-4 hover:text-opacity-100 select-none">
                Политика конфиденциальности
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Landing;
