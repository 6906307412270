import { ReactComponent as ClaudIcon } from './../../../../assets/icons/claud.svg';
import { ReactComponent as PartIcon } from './../../../../assets/icons/rocket-landing.svg';
import { ReactComponent as ServerIcon } from './../../../../assets/icons/server.svg';
import { ReactComponent as StarsIcon } from './../../../../assets/icons/stars.svg';

const ChoisePlatform = () => {
  return (
    <div id="about" className="w-full h-fit flex flex-col items-center px-[20px] lg:px-[10%]">
      <div className="w-full flex flex-col">
        <div className="relative w-full h-fit flex justify-center mx-auto">
          <StarsIcon className="max-h-[200px] mx-auto" />
          <PartIcon className="absolute bottom-0 w-[100px] h-[100px]" />
        </div>
      </div>
      <div className="max-w-[600px] mx-auto pt-7 text-center text-[#CFD1E4] text-4xl">
        Мы предлагаем <span className="lending-text-gradient">два варианта</span> использования
        платформы:
      </div>
      <div className="flex flex-col gap-[20px] lg:gap-[40px] pt-[60px] lg:flex-row">
        <div className="max-w-[500px] lg:w-1/2 flex flex-col justify-center items-center p-[40px] rounded-[20px] bg-gradient border border-white border-opacity-30">
          <ClaudIcon className="h-[30px] w-fit" />
          <div className="flex flex-col gap-y-4 pt-[40px] text-center">
            <span className="text-white text-2xl font-semibold">Облачная платформа</span>
            <span className="text-[#CFD1E4] text-base font-normal">
              Гибкость и масштабируемость в облаке, обеспечивая легкий доступ к обучению.
            </span>
          </div>
        </div>
        <div className="max-w-[500px] lg:w-1/2 flex flex-col justify-center items-center p-[40px] border border-white border-opacity-30 rounded-[20px] bg-gradient">
          <ServerIcon className="h-[30px] w-fit" />
          <div className="flex flex-col gap-y-4 pt-[40px] text-center">
            <span className="text-white text-2xl font-semibold">Установка на сервер</span>
            <span className="text-[#CFD1E4] text-base font-normal">
              Полный контроль над данными и настройками системы, повышенный уровень безопасности.
            </span>
          </div>
        </div>
      </div>
      <div className="mt-[60px] lg:my-[120px] p-1 border border-white border-opacity-20 rounded-[55px]">
        {/* <button
          type="button"
          className="py-3 px-6 text-white font-bold text-lg bg-buttonBg rounded-[55px] uppercase"
        >
          ВЫБРАТЬ ПЛАТФОРМУ
        </button> */}
      </div>
    </div>
  );
};

export default ChoisePlatform;
