import { NavLink } from 'react-router-dom';
import book from 'assets/icons/couch/book.svg';
import edit from 'assets/icons/couch/cedit.svg';
import documentIcon from 'assets/icons/couch/document-text.svg';
import journal from 'assets/icons/couch/journal.svg';
import password from 'assets/icons/couch/password.svg';
import dashboardIcon from 'assets/icons/dashboard.svg';
import { ReactComponent as DividerIcon } from 'assets/icons/divider.svg';
import groupIcon from 'assets/icons/group-white.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import userIcon from 'assets/icons/user-white.svg';
import Avatar from 'components/Avatar';
import LMSTitle from 'components/LMSTitle';
import { authSlice } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

const SideBar = () => {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const navItems = [
    {
      id: 0,
      title: 'Аналитика',
      link: '/hr/',
      icon: dashboardIcon,
    },
    {
      id: 1,
      title: 'Пользователи',
      link: '/hr/users/coaches',
      icon: userIcon,
    },
    {
      id: 2,
      title: 'Группы',
      link: '/hr/groups',
      icon: groupIcon,
    },
    {
      id: 3,
      title: 'Курсы',
      link: '/hr/courses',
      icon: book,
    },
    {
      id: 4,
      title: 'База обученности',
      link: '/hr/training',
      icon: documentIcon,
    },
    {
      id: 5,
      title: 'Журнал посещаемости',
      link: '/hr/journal',
      icon: journal,
    },
    {
      id: 6,
      title: 'Редактировать профиль',
      link: '/hr/edit-profile',
      icon: edit,
    },
    // {
    //   id: 7,
    //   title: 'Пароль',
    //   link: '/hr/change-password',
    //   icon: password,
    // },
  ];

  const logout = () => {
    dispatch(authSlice.actions.logout());
  };

  return (
    <div className="w-full h-full flex flex-col bg-sidebar rounded-3xl">
      <div className="w-full h-fit flex flex-row justify-between py-3 px-3 border-b border-b-[#3D436B] bg-transparent lg:flex-col lg:border-b-0">
        <div className="text-3xl text-white text-center font-semibold select-none pt-[30px]">
          {user?.business?.name ? <span>{user?.business?.name}</span> : 'CTE PLATFORM'}
        </div>
        <DividerIcon className="hidden mt-[30px] mx-auto w-[calc(100%-12px)] lg:block" />
      </div>
      <div className="flex flex-col items-center gap-x-4 py-6 px-4 hidden">
        <div className="w-[90px] h-[90px] 2xl:w-[120px] 2xl:h-[120px]">
          <Avatar image={user?.avatar} />
        </div>
        {user?.fio && (
          <LMSTitle tag={'h3'} className="text-center text-white text-xl font-semibold lg:pt-5 ">
            {user?.fio}
          </LMSTitle>
        )}
      </div>
      <div className="flex flex-col overflow-y-scroll">
        <nav className="w-full h-fit">
          <ul>
            {navItems &&
              navItems?.map((el) => {
                return (
                  <li
                    key={el.id}
                    className="border-[#3D436B] border-t cursor-pointer last-of-type:border-b-none"
                  >
                    <NavLink
                      to={`${el.link}`}
                      className={({ isActive }) => {
                        return `
                          flex flex-row items-center gap-x-5 px-4 py-5 text-white hover:text-white lg:px-[30px] 2xl:text-[22px]
                          ${
                            isActive
                              ? 'bg-[#26A7EF] bg-opacity-[6%] border-blue border-r-[2px]'
                              : ''
                          }
                        `;
                      }}
                    >
                      <img src={el.icon} alt="" />
                      {el.title}
                    </NavLink>
                  </li>
                );
              })}
          </ul>
        </nav>
      </div>
      <button
        onClick={() => logout()}
        className="w-full flex flex-row items-center justify-start gap-x-5 mt-auto p-[26px] text-white text-xl font-medium border-t border-t-[#3D436B]"
      >
        <LogoutIcon />
        Выйти из аккаунта
      </button>
    </div>
  );
};

export default SideBar;
