import { useState } from 'react';
import { ReactComponent as SuccessIcon } from 'assets/icons/check.svg';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as NegativeIcon } from 'assets/icons/close.svg';
import LMSPagination from 'components/LMSPagination';

const CoachCourseStudentLessons = () => {
  const [openLessons, setOpenLessons] = useState<{ [key: number]: boolean }>({});

  const handleDropdownClick = (index: number) => {
    setOpenLessons((prevOpenLessons) => ({
      ...prevOpenLessons,
      [index]: !prevOpenLessons[index],
    }));
  };

  const lessons = [
    {
      id: 0,
      lessonName: 'Тестирование пользовательского интерфейса',
      date: '22.10.2021',
      attendance: true,
    },
    {
      id: 1,
      lessonName: 'Оформление баг репорта',
      date: '23.10.2021',
      attendance: true,
    },
    {
      id: 2,
      lessonName: 'Тестирование функционала',
      date: '24.10.2021',
      attendance: true,
    },
    {
      id: 3,
      lessonName: 'Админ панель',
      date: '25.10.2021',
      attendance: true,
    },
    {
      id: 4,
      lessonName: 'Тест кейс',
      date: '',
      attendance: false,
    },
    {
      id: 5,
      lessonName: 'FLAGMAN «YOURT»',
      date: '27.10.2021',
      attendance: true,
    },
  ];

  return (
    <div className="flex flex-col gap-16 p-6 bg-[#0c1231] rounded-tr-[26px] rounded-b-[26px]">
      <div className="w-full h-full flex flex-col">
        <table className="hidden w-full table-auto md:table">
          <thead>
            <tr className="h-12 text-white text-sm font-normal text-opacity-40">
              <th className="w-[5%] text-left">№</th>
              <th className="w-[40%] text-left">Название урока</th>
              <th className="w-[30%] text-left">Дата просмотра</th>
              <th className="w-[20%] text-left">Посещаемость</th>
            </tr>
          </thead>
          <tbody className="w-full">
            {lessons &&
              lessons.map((el) => (
                <tr key={el.id} className="w-full text-white text-base font-normal tracking-wide">
                  <td className="h-16 text-white text-lg">{el.id + 1}</td>
                  <td className="h-16 flex flex-row items-center gap-x-5">{el.lessonName}</td>
                  <td className="h-16">{el.date ? el.date : '-'}</td>
                  <td className="h-16">
                    {el.attendance ? (
                      <div className="flex flex-row items-center gap-6">
                        <SuccessIcon className="text-green" />
                        Посетил
                      </div>
                    ) : (
                      <div className="flex flex-row items-center gap-6">
                        <NegativeIcon className="w-6 text-[#FF5449]" />
                        Пропустил
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex flex-col md:hidden">
          {lessons &&
            lessons.map((el, idx) => (
              <div key={el.id}>
                <div
                  onClick={() => handleDropdownClick(idx)}
                  className="w-full py-3 text-white text-base font-normal border-t border-white border-opacity-10 cursor-pointer first-of-type:border-none"
                >
                  <div className="flex flex-row gap-1 text-xs">
                    №<span>{idx + 1}</span>
                  </div>
                  <div className="w-full flex items-baseline justify-between">
                    {el.lessonName}
                    <DropdownIcon className={`${openLessons[idx] ? 'rotate-180' : ''}`} />
                  </div>
                </div>
                {openLessons[idx] && (
                  <div className="grid grid-cols-2 py-3">
                    <div className="flex flex-col">
                      <span className="text-white text-opacity-40">Дата просмотра</span>
                      <span className="text-white">{el.date ? el.date : '-'}</span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-white text-opacity-40">Посещаемость</span>
                      <div className="text-white">
                        {el.attendance ? (
                          <div className="flex flex-row items-center gap-1">
                            <SuccessIcon className="text-green" />
                            Посетил
                          </div>
                        ) : (
                          <div className="flex flex-row items-center gap-1">
                            <NegativeIcon className="w-6 text-[#FF5449]" />
                            Пропустил
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>

      <div className="w-full flex justify-start items-center mt-auto pt-6 border-white border-t border-opacity-40 md:pt-10">
        <LMSPagination current={1} lastPage={5} onChange={() => {}} />
      </div>
    </div>
  );
};

export default CoachCourseStudentLessons;
