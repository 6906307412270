import React, { useMemo, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const CoachCourseStudentAnalytics = () => {
  const [efficiencies, setEfficiencies] = useState([
    { id: 1, title: 'Пройденные уроки', percentage: 10, color: '#58DB66' },
    { id: 2, title: 'Не пройденный уроки ', percentage: 30, color: '#D32F2F' },
    { id: 3, title: 'Уроки в процессе', percentage: 10, color: '#EFB826' },
  ]);

  const chartData = useMemo(() => {
    return {
      datasets: [
        {
          label: 'Эффективность',
          data: efficiencies.map((p) => p.percentage),
          backgroundColor: efficiencies.map((p) => p.color),
          hoverOffset: 4,
          borderWidth: 0,
          cutout: '85%',
          borderRadius: 20,
          offset: 10,
        },
      ],
    };
  }, [efficiencies]);

  const options = {
    maintainAspectRatio: false,
    tooltips: {
      enabled: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="flex flex-col gap-6 px-10 py-12 bg-[#0c1231] rounded-tr-[26px] rounded-b-[26px] md:flex-row md:gap-x-20">
      <div className="w-full flex flex-col p-5 bg-white bg-opacity-10 border border-white border-opacity-40 rounded-2xl md:max-w-[340px]">
        <span className="text-white text-lg font-semibold">Статистика ученика</span>

        <div className="flex justify-center">
          <div className="w-[190px] h-[190px] mt-5 mb-10">
            <Doughnut data={chartData} options={options} />
          </div>
        </div>
        <div className="flex flex-col gap-4 mt-auto">
          {efficiencies.map((item) => (
            <div key={item.id} className="flex items-center space-x-3 text-sm text-white">
              <span className="block w-4 h-1 rounded" style={{ backgroundColor: item.color }} />
              <span className="flex-1 text-white/50">{item.title}</span>
              <span>{item.percentage}%</span>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full flex flex-col gap-6">
        <div className="flex flex-row justify-between gap-x-11 p-6 bg-white bg-opacity-10 border border-white border-opacity-40 rounded-2xl">
          <div className="flex flex-col">
            <span className="text-white text-lg font-semibold">Количество пройденных уроков</span>
            <span className="text-[#A0AEC0] text-xs font-normal">
              общее количество пройденных уроков
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-white text-lg font-semibold">14 из 24</span>
            <span className="text-[#A0AEC0] text-xs font-normal">+0%</span>
          </div>
        </div>
        <div className="flex flex-row justify-between gap-x-11 p-6 bg-white bg-opacity-10 border border-white border-opacity-40 rounded-2xl">
          <div className="flex flex-col">
            <span className="text-white text-lg font-semibold">Среднее время прохождения</span>
            <span className="text-[#A0AEC0] text-xs font-normal">
              среднее время прохождения 1 урока
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-white text-lg font-semibold">16 мин 8 с</span>
            <span className="text-[#A0AEC0] text-xs font-normal">+0%</span>
          </div>
        </div>
        <div className="flex flex-row justify-between gap-x-11 p-6 bg-white bg-opacity-10 border border-white border-opacity-40 rounded-2xl">
          <div className="flex flex-col">
            <span className="text-white text-lg font-semibold">Средний балл</span>
            <span className="text-[#A0AEC0] text-xs font-normal">средний балл студента</span>
          </div>
          <div className="flex flex-col">
            <span className="text-white text-lg font-semibold">65,6%</span>
            <span className="text-[#58DB66] text-xs font-normal">+ 1,18%</span>
          </div>
        </div>
        <div className="flex flex-row justify-between gap-x-11 p-6 bg-white bg-opacity-10 border border-white border-opacity-40 rounded-2xl">
          <div className="flex flex-col">
            <span className="text-white text-lg font-semibold">-</span>
            <span className="text-[#A0AEC0] text-xs font-normal">-</span>
          </div>
          <div className="flex flex-col">
            <span className="text-white text-lg font-semibold">10</span>
            <span className="text-[#A0AEC0] text-xs font-normal">-</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachCourseStudentAnalytics;
