import { useEffect, useState } from 'react';
import { fetchCoursesGroupList } from 'modules/coach/api/repository/courses-group-list';
import {
  TCourseGroupMetaData,
  TCourseMetaData,
  TLessons,
  TModuleMetaData,
} from 'modules/coach/entities/CourseGroupList';
import useHandleError from 'hooks/handleError';
import { ErrorResponse } from 'entities/Error';

export const useCoursesListData = (selectedCourseId?: number) => {
  const [coursesList, setCoursesList] = useState<TCourseMetaData[]>([]);
  const [groups, setGroupsList] = useState<TCourseGroupMetaData[]>([]);
  const [modules, setModules] = useState<TModuleMetaData[]>([]);
  const [lessons, setLessons] = useState<TLessons[]>([]);
  const { handleCommonError } = useHandleError();

  useEffect(() => {
    const params = {
      course_id: selectedCourseId ? selectedCourseId : null,
    };

    fetchCoursesGroupList(params)
      .then((res) => {
        const groups = res.flatMap((l) => l.groups);
        const module = res.flatMap((m) => m.modules);
        const lessons = groups.flatMap((l) => l.lessons);

        setCoursesList(res);
        setGroupsList(groups);
        setModules(module);
        setLessons(lessons);
      })
      .catch((err: unknown) => {
        const error = err as ErrorResponse;
        handleCommonError(error);
      });
  }, [selectedCourseId]);

  return { coursesList, groups, modules, lessons };
};
