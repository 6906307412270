import { IHandbooks } from 'modules/coach/entities/Handbooks';
import { ILessons } from 'modules/coach/entities/Lessons';
import request from 'api/axios-instance';
import { Option } from 'entities/Option';

import { IHandbookOptionDao, IHandbooksDao } from '../dao/HandbooksDao';
import { LessonsDao } from '../dao/LessonsDao';

export const fetchHandbookLessons = (groupId: number) =>
  request.get(`v1/lessons?course_group_id=${groupId}`).then((res) => {
    const lessons: ILessons[] = res.data.map(mapLessonsDaoToEntity);

    return lessons;
  });

export const fetchHandbooks = () =>
  request.get(`v2/coach/catalog/lists`).then((res) => {
    const handbooks: IHandbooks = handbooksDaoToEntity(res.data);

    return handbooks;
  });

export const fetchHomeworkCourses = (groupId?: number) =>
  request
    .get(`v2/coach/catalog/for-homeworks/courses`, {
      params: {
        group_id: groupId,
      },
    })
    .then((res) => res.data.data.map(mapOptionDaoToEntity));

export const fetchHomeworkGroups = (courseId?: number) =>
  request
    .get(`v2/coach/catalog/for-homeworks/groups`, {
      params: {
        course_id: courseId,
      },
    })
    .then((res) => res.data.data.map(mapOptionDaoToEntity));

export const fetchHandbookCalendarCourses = (params?: { [key: string]: string | number }) =>
  request
    .get(`v2/catalog/for-calendar/courses`, {
      params,
    })
    .then((res) => res.data.data.map(mapOptionDaoToEntity));

export const fetchHandbookCalendarGroups = (params?: { [key: string]: string | number }) =>
  request
    .get(`v2/catalog/for-calendar/groups`, {
      params,
    })
    .then((res) => res.data.data.map(mapOptionDaoToEntity));

function mapLessonsDaoToEntity(lessons: LessonsDao): ILessons {
  return {
    id: lessons.id,
    name: lessons.name,
    canAddTest: lessons.can_add_test,
    description: lessons.description,
    isTest: lessons.is_test,
    position: lessons.position,
  };
}

function handbooksDaoToEntity(handbooks: IHandbooksDao): IHandbooks {
  return {
    roles: handbooks.roles.map(mapOptionDaoToEntity),
    genders: handbooks.genders.map(mapOptionDaoToEntity),
    studyTypes: handbooks.study_types.map(mapOptionDaoToEntity),
    languages: handbooks.languages.map(mapOptionDaoToEntity),
    coachStatuses: handbooks.coach_statuses.map(mapOptionDaoToEntity),
    homeworkStatuses: handbooks.homework_statuses.map(mapOptionDaoToEntity),
  };
}

function mapOptionDaoToEntity(option: IHandbookOptionDao): Option {
  return {
    value: option.id,
    label: option.name,
  };
}
