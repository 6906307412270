import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import AddNewHomework from 'modules/coach/components/AddNewHomework';

const NewHomework = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/coach/homeworks');
  };

  return (
    <div className="w-full h-full flex flex-col mt-20 lg:mt-0">
      <button
        type="button"
        onClick={() => goBack()}
        className="w-fit flex flex-row items-center justify-center gap-x-2 py-3  mt-4 pl-3 pr-5 text-current text-[14px] font-medium bg-white bg-opacity-60 rounded-[36px] border-none outline-none uppercase"
      >
        <ArrowBack />
        назад
      </button>
      <div className="flex flex-col mt-10">
        <AddNewHomework />
      </div>
    </div>
  );
};

export default NewHomework;
