import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import BaseImage from 'components/BaseImage';
import LMSButton from 'components/LMSButton';
import LMSTitle from 'components/LMSTitle';
import CreateModulesModal from 'modules/hr/components/CreateModulesModal';
import Loading from 'modules/student/components/Loading';
import Title from 'modules/student/components/Title';

import { fetchCourseById } from '../../api/repository/courses';
import { ICourseDetail } from '../../entities/Course';

const HrCourseDetail = () => {
  const { id } = useParams();

  const [isLoading, setLoading] = useState(false);
  const [course, setCourse] = useState<ICourseDetail | null>(null);
  const [isShowCreateModulesModal, setIsShowCreateModulesModal] = useState<boolean>(false);

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    fetchCourseById(+id)
      .then((res) => {
        setCourse(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const badgeClass =
    'flex items-center mb-2 px-5 py-2 bg-white/20 text-xs md:text-base rounded-full truncate overflow-hidden';

  return (
    <>
      {isLoading ? (
        <div className="mt-20">
          <Loading />
        </div>
      ) : null}

      {course && !isLoading ? (
        <div className="w-full">
          <div className="flex flex-row justify-end">
            <LMSButton onClick={() => setIsShowCreateModulesModal(true)}>
              Добавить модуль к курсу
            </LMSButton>
          </div>

          <div className="flex flex-col md:flex-row p-4 md:p-10 rounded-3xl bg-studentCardBg mt-6 md:mt-10 md:space-x-5 xl:space-x-10 space-y-3 md:space-y-0">
            <div className="md:w-[210px] xl:w-[260px] h-[260px] rounded-xl overflow-hidden shrink-0">
              <BaseImage image={course.imageUrl} />
            </div>

            <div className="flex flex-col">
              <div className="flex items-center flex-wrap space-x-1 mb-4 md:mb-14">
                <div className={badgeClass}>
                  <CalendarIcon className="mr-3" />
                  {course.dateFrom}-{course.dateTo}
                </div>
                <p className={badgeClass}>{course.direction || 'Не указан'}</p>
                {course.courseType && (
                  <div className={badgeClass}>
                    <span
                      className={`w-1 h-1 rounded-full mr-2 ${
                        course.courseType === 'online' ? 'bg-green' : 'bg-error'
                      }`}
                    />
                    <p>{course.courseType === 'online' ? 'онлайн' : 'оффлайн'}</p>
                  </div>
                )}
              </div>

              <Title>{course.name}</Title>
            </div>
          </div>

          {course.modules?.length ? (
            <div className="mt-7 md:mt-10">
              <LMSTitle tag="h4" className="text-white text-3xl">
                Список модулей
              </LMSTitle>
              <div className="grid grid-cols-1 lg:grid-cols-[1fr_250px] xl:grid-cols-[1fr_360px] gap-5">
                <div className="mt-6 rounded-2xl bg-studentCardBg overflow-hidden">
                  <div className="grid grid-cols-[60px_1fr] py-4 text-white/50 bg-white/10">
                    <p className="text-center">№</p>
                    <p>Модуль</p>
                  </div>
                  {course.modules.map((module, index) => (
                    <div
                      key={module.id}
                      className="grid grid-cols-[60px_1fr] items-center py-4 border-b border-white/20 last:border-b-0 cursor-pointer hover:bg-white/5"
                    >
                      <p className="text-center">{index + 1}</p>
                      <div className="flex items-center space-x-4">
                        <p>{module.title}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}

          {isShowCreateModulesModal ? (
            <CreateModulesModal
              modules={course.modules}
              onClose={() => setIsShowCreateModulesModal(false)}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default HrCourseDetail;
