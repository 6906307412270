import { useEffect, useMemo, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import LMSButton from 'components/LMSButton';
import MessageCard from 'components/MessageCard';
import PDFView from 'components/PDF';
// import { fetchHomeWorkMark } from 'modules/coach/api/repository/homework-mark';
import FileDownloadButton from 'modules/coach/components/DownLoadButton';
import { IHomeWorkMark } from 'modules/student/api/dao/HomeWork.dao';
import { fetchStudentHomeWorkMark } from 'modules/student/api/repository/homework';
import { fetchHomeWorkMessages } from 'modules/student/api/repository/homework-messages';
import { IHomeWorkMessages } from 'modules/student/entities/HomeWorkMessages';
import useHandleError from 'hooks/handleError';
import { useNotification } from 'hooks/notification';
import { ErrorResponse } from 'entities/Error';

import { uploadFile } from '../../api/repository/handbooks';
import { fetchCourseLesson, sendHomework } from '../../api/repository/my-courses';
import Loading from '../../components/Loading';
import TextEditor from '../../components/TextEditor';
import { ISingleLessonFiles, SingleLesson } from '../../entities/Lesson';

const HomeworkDetail = () => {
  const navigate = useNavigate();
  const { id, lessonId } = useParams();
  const { handleCommonError } = useHandleError();
  const notify = useNotification();

  const [isLoading, setLoading] = useState(false);
  const [lesson, setLesson] = useState<SingleLesson | null>(null);
  const [answer, setAnswer] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [homeworkMessages, setHomeworkMessages] = useState<IHomeWorkMessages[]>([]);
  const [homeworkMark, setHomeworkMark] = useState<number | null>(null);

  useEffect(() => {
    if (!id || !lessonId) return;

    setLoading(true);
    fetchCourseLesson(+lessonId)
      .then((res) => {
        setLesson(res);
      })
      .finally(() => {
        setLoading(false);
      });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [id, lessonId]);

  useEffect(() => {
    fetchHomeWorkMessages(Number(lessonId), Number(id))
      .then((res) => {
        setHomeworkMessages(res);
      })
      .catch((e) => console.error(e));
  }, [id, lessonId]);

  useEffect(() => {
    fetchStudentHomeWorkMark(Number(lessonId), Number(id))
      .then((res) => {
        setHomeworkMark(res?.mark);
      })
      .catch((e) => console.error(e));
  }, [id, lessonId]);

  const mapFiles = (files: ISingleLessonFiles[]) => {
    return {
      pdf: files.filter((file: ISingleLessonFiles) => file.name.includes('pdf')),
      without: files.filter((file: ISingleLessonFiles) => !file.name.includes('pdf')),
    };
  };

  const chartData = useMemo(() => {
    return {
      datasets: [
        {
          data: [homeworkMark, homeworkMark ? 100 - homeworkMark : 0],
          backgroundColor: [
            homeworkMark && homeworkMark < 50 ? '#FF0000' : '#58DB66',
            'rgba(88,219,102,0.2)',
          ],
          borderWidth: 0,
          cutout: homeworkMark,
          borderRadius: 20,
          offset: 6,
        },
      ],
    };
  }, [homeworkMark]);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const onSubmit = async () => {
    let filePath = '';
    try {
      setLoading(true);
      if (file) {
        const fd = new FormData();
        fd.append('file', file);
        filePath = await uploadFile(fd);
      }

      await sendHomework(+id!, +lessonId!, {
        content: answer,
        ...(filePath && { files: filePath }),
      });

      notify.success('Домашнее задание успешно отправлено');

      // navigate(`/student/active/${id}`);
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      handleCommonError(error);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading && !lesson) {
    return (
      <div>
        <Loading theme="dark" />
      </div>
    );
  }

  if (!lesson) return <div />;

  return (
    <div>
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between space-y-4 md:space-y-0">
        <p className="text-[26px] md:text-3xl">
          Урок {lesson.position}: {lesson.name}
        </p>
        {lesson?.homework?.deadline && (
          <div className="flex items-center space-x-3 bg-black/30 py-2 md:py-4 px-3 md:px-5 rounded-full">
            <CalendarIcon className="text-green" />
            <p className="text-base md:text-lg">
              Сдать до: {lesson?.homework?.deadline?.split(' ')[0]}
            </p>
          </div>
        )}
      </div>

      <div className="mt-5 p-4 md:p-10 rounded-2xl theme-student-card">
        <p className="text-[26px] md:text-[32px]">Домашнее задание</p>

        <div
          className="mt-6 md:mt-8 text-lg student-description text-current"
          dangerouslySetInnerHTML={{ __html: lesson?.homework?.description || '' }}
        />
      </div>

      {lesson?.homework?.fileUrl && lesson?.homework?.fileUrl.length ? (
        <div className="w-full flex flex-col gap-5 mt-8">
          {lesson.homework.fileUrl?.length || lesson.homework.videoUrl?.length ? (
            <h4 className="text-current text-3xl font-medium">Материалы к заданию</h4>
          ) : (
            ''
          )}
          <div className="flex flex-wrap gap-4">
            {lesson?.homework?.fileUrl?.length ? (
              <div>
                {mapFiles(lesson?.homework?.fileUrl).pdf?.map((file) => (
                  <div key={file?.downloadLink} className="mb-4">
                    <PDFView fileLink={file?.downloadLink} />
                  </div>
                ))}
                {mapFiles(lesson?.homework?.fileUrl).without?.map((el, idx) => (
                  <div
                    key={idx}
                    className="w-[48%] flex flex-row justify-between items-center p-7 bg-gradient rounded-[14px]"
                  >
                    <div className="max-w-[400px] flex flex-col gap-2">
                      <p className="flex flex-row items-end gap-x-2 text-current text-ld">
                        {el.name}
                        <span className="text-current text-opacity-30 text-base">
                          {(Number(el.size) / (1024 * 1024)).toFixed(2)} мб
                        </span>
                      </p>
                    </div>
                    <FileDownloadButton fileLink={el.downloadLink} name={el.name} />
                  </div>
                ))}
              </div>
            ) : (
              ''
            )}

            {lesson?.homework?.videoUrl?.length
              ? lesson?.homework?.videoUrl?.map((el, idx) => (
                  <div
                    key={idx}
                    className="w-[48%] flex flex-row justify-between items-center p-7 bg-gradient rounded-[14px]"
                  >
                    <div className="max-w-[400px] flex flex-col gap-2">
                      <p className="flex flex-row items-end gap-x-2 text-current text-ld">
                        {el.name}
                        <span className="text-current text-opacity-30 text-base">
                          {(Number(el.size) / (1024 * 1024)).toFixed(2)} мб
                        </span>
                      </p>
                    </div>
                    <FileDownloadButton fileLink={el.downloadLink} name={el.name} />
                  </div>
                ))
              : ''}
          </div>
        </div>
      ) : null}

      <div className="mt-8 p-4 md:p-10 rounded-2xl theme-student-card">
        <p className="text-[26px] md:text-[32px]">Результат</p>
        <p className="max-w-[1060px] mt-4 md:mt-7">
          Готовый результат отправьте в формате pdf или doc/docx или в виде ссылки на
          google-документ (не забудьте открыть доступ к файлу)
        </p>
      </div>

      <div className="flex flex-col gap-y-8 mt-8">
        <p className="text-[26px] md:text-[32px]">Проверка домашнего задания</p>

        {homeworkMark ? (
          <div className="max-h-[300px] w-fit flex flex-row justify-between -mt-6 p-6 bg-gradient rounded-[20px]">
            <div className="w-1/2">
              <h4 className="pb-10 text-current text-2xl">
                {homeworkMark && homeworkMark < 50
                  ? 'Вы не набрали необходимые баллы'
                  : 'Задание выполнено'}
              </h4>
            </div>
            <div className="relative w-full max-w-[100px] max-h-[100px]">
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[100px] flex flex-col text-center text-current">
                <span>{homeworkMark} из 100</span>
              </div>
              {chartData ? <Doughnut data={chartData} options={options} /> : null}
            </div>
          </div>
        ) : (
          ''
        )}

        {homeworkMessages &&
          homeworkMessages.map((message, idx) => {
            return (
              <MessageCard
                key={message.id}
                isLast={idx === homeworkMessages.length - 1}
                authorAvatar={message?.author?.avatar}
                authorFio={message?.author?.fio}
                statusId={message?.statusId}
                authorId={message?.author?.id}
                studentFio={message?.student.fio}
                content={message?.content}
                lessonName={lesson.name}
                createdAt={message.createdAt}
                files={message.files}
              />
            );
          })}

        {!homeworkMark && (
          <div className="p-4 md:p-6 rounded-2xl theme-student-card">
            <TextEditor value={answer} onChange={setAnswer} />

            <div className="flex flex-col md:flex-row items-start justify-between space-y-4 md:space-y-0 md:space-x-4 mt-6">
              <div className="flex items-center space-x-1.5 space-y-1.5 flex-wrap w-full">
                <label className="flex items-center justify-center w-full md:w-auto px-[25px] py-2 md:py-3.5 rounded-full theme-default-button--active text-current cursor-pointer">
                  <UploadIcon className="mr-2" />
                  <span>Загрузить документ</span>
                  <input
                    className="w-0 h-0 opacity-0"
                    type="file"
                    accept=".pdf,.doc,.docx"
                    onChange={(event) => setFile(event.target.files && event.target.files[0])}
                  />
                </label>
                {file ? (
                  <div className="flex items-center max-w-[300px] space-x-1 py-2 px-3 rounded-full theme-background--grey">
                    <button
                      className="shrink-0 w-5 h-5 flex items-center justify-center rounded-full bg-white/50 text-base"
                      onClick={() => setFile(null)}
                    >
                      &times;
                    </button>
                    <span className="truncate">{file.name}</span>
                  </div>
                ) : null}
              </div>
              <LMSButton className="w-full md:w-auto" loading={isLoading} onClick={onSubmit}>
                ОТПРАВИТЬ
              </LMSButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeworkDetail;
