import React, { useState } from 'react';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Avatar from 'components/Avatar';

import StudentInfoModal from '../../components/StudentInfoModal';

type TAttendance = {
  attendance: string;
  date: string;
};

interface Props {
  student: {
    avatar: string;
    id: number;
    full_name: string;
    attendance: TAttendance[];
  };
  index: number;
  rangeDays: string[];
}

const AttendanceTable: React.FC<Props> = ({ student, index, rangeDays }) => {
  const [studentId, setStudentId] = useState(0);

  const checkIsEmptyDay = (day: string) => {
    const isHave = student.attendance.find((item) => item.date === day);
    return isHave ? isHave.attendance : '';
  };

  const onStudentSelect = (id: number) => {
    setStudentId(id);
  };

  return (
    <div className="w-full flex">
      <aside className="flex px-8 py-4  flex-row gap-10 items-center max-w-[340px] w-full">
        <span className="hidden md:block">{index + 1}</span>
        <div
          className="flex items-center space-x-3 cursor-pointer"
          onClick={() => onStudentSelect(student.id)}
        >
          <div className="w-9 h-9 rounded-full overflow-hidden">
            <Avatar image={student?.avatar} alt={student.full_name + ' аватар'} />
          </div>
          <p>{student.full_name}</p>
        </div>
      </aside>
      <div className="grid grid-cols-hrJournalTable w-full">
        {rangeDays.length > 0 &&
          rangeDays.map((item) => {
            return (
              <div
                className="min-w-[106px] px-2 py-2 flex justify-center items-center relative"
                key={item}
              >
                {checkIsEmptyDay(item) === 'attended' ? (
                  <div className="tooltip">
                    <CheckIcon className="w-10 h-10 text-[#58DB66]" />
                    <span className="tooltiptext text-medium">Присутствовал</span>
                  </div>
                ) : checkIsEmptyDay(item) === 'skipped' ? (
                  <div className="tooltip">
                    <CloseIcon className="w-10 h-10 text-[#FF5449]" />
                    <span className="tooltiptext text-medium">Отсутствовал</span>
                  </div>
                ) : checkIsEmptyDay(item) === 'skipped_with_reason' ? (
                  <div className="tooltip">
                    <CloseIcon className="w-10 h-10 text-[#ffa31a]" />
                    <span className="tooltiptext text-medium">
                      Отсутствовал по уважительной причине
                    </span>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            );
          })}
      </div>

      {studentId ? (
        <StudentInfoModal studentId={studentId} onClose={() => setStudentId(0)} />
      ) : null}
    </div>
  );
};

export default AttendanceTable;
