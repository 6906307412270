import { ReactComponent as AndroidIcon } from '../../../../assets/icons/android.svg';
import { ReactComponent as AppleIcon } from '../../../../assets/icons/apple.svg';
// import { ReactComponent as IphoneIcon } from '../../../../assets/icons/iPhone16.svg';
import { ReactComponent as LinesIcon } from '../../../../assets/icons/lending-lines.svg';
import { ReactComponent as MobileMessageIcon } from '../../../../assets/icons/mobile-message.svg';
import { ReactComponent as RealmyIcon } from '../../../../assets/icons/realmy.svg';
import { ReactComponent as StarsBlockIcon } from '../../../../assets/icons/srarsblock.svg';

import { ReactComponent as StarsIcon } from './../../../../assets/icons/stars.svg';

const MobileAppBlock = () => {
  return (
    <div id="apps" className="w-full h-fit flex flex-col px-[20px] md:mt-[100px] lg:px-0 lg:mt-0">
      <div className="relative w-full h-fit flex flex-row items-center justify-between mt-[100px] lg:mt-0">
        <LinesIcon className="hidden max-h-[425px] w-[45%] rotate-180 lg:block" />
        <StarsIcon className="absolute top-[-130px] lg:top-[30px] w-full max-h-[300px] mx-auto" />
        <div className="w-full mx-[50px] md:mx-[35%] lg:w-[16%] p-[4px] border rounded-[20px] z-30 lg:mx-0">
          <div className="w-full flex flex-row gap-x-[6px] py-[6px] px-[8px] border rounded-[18px]">
            <div className="w-1/2 flex justify-center items-center p-4 bg-[#515169] rounded-[10px]">
              <AndroidIcon className="w-[44px] h-[40px]" />
            </div>
            <div className="w-1/2 flex justify-center items-center p-4 bg-[#515169] rounded-[10px]">
              <AppleIcon />
            </div>
          </div>
        </div>
        <LinesIcon className="hidden max-h-[425px] w-[45%] lg:block" />
      </div>
      <div className="max-w-[700px] w-full h-fit flex flex-col mx-auto mt-[100px] lg:mt-[-100px]">
        <h4 className="text-center text-white text-[50px] leading-[100%] font-semibold">
          Мобильное приложение для устройств{' '}
          <span className="lending-text-gradient">Android и iOS</span>
        </h4>
        <p className="mx-auto pt-[46px] text-center text-[#CFD1E4] text-xl">
          Обеспечиваем удобство использования платформы на мобильных устройствах и возможность
          учиться и проводить онлайн-встречи в любом месте и в любое время.
        </p>
      </div>
      <div className="w-full h-fit flex flex-col pt-[50px]">
        <div className="flex flex-row">
          <div className="relative w-1/2 h-[600px] flex">
            <StarsBlockIcon className="ml-[20px]" />
            <div className="md:max-w-[320px] min-w-[300px] md:min-w-auto w-full h-fit flex flex-col md:ml-auto mt-[620px] md:mt-[260px] mr-[18%] p-6 rounded-[16px] border border-white border-opacity-30">
              <MobileMessageIcon />
              <span className="mt-6 text-white text-base font-semibold">
                Удобное взаимодействие
              </span>
              <p className="pt-2 text-white text-opacity-50 text-sm font-light">
                Студенты и преподаватели могут легко общаться, задавать вопросы и получать ответы
                через наше приложение.
              </p>
            </div>
            <RealmyIcon className="absolute top-0 right-[-200px] max-h-[600px] w-fit" />
          </div>
          <div className="relative w-1/2 h-[600px]"></div>
        </div>
      </div>
      <div className="relative mt-[240px] md:mt-0 w-fit mx-auto h-fit p-1 border border-white border-opacity-20 rounded-[55px] z-30">
        <div className="p-1 border border-white border-opacity-20 rounded-[55px]">
          {/* <button
            type="button"
            className="py-3 px-6 text-white font-bold text-lg bg-buttonBg rounded-[55px]"
          >
            ПОПРОБОВАТЬ БЕСПЛАТНО
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default MobileAppBlock;
