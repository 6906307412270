import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as CloseEyeIcon } from 'assets/icons/close-eye.svg';
import { ReactComponent as OpenEyeIcon } from 'assets/icons/open-eye.svg';
import LMSTitle from 'components/LMSTitle';
import { authSlice } from 'store/auth';
import useHandleError from 'hooks/handleError';
import { useNotification } from 'hooks/notification';
import { useAppDispatch } from 'hooks/redux';
import { checkStudentToken, loginStudent } from 'api/repositories/users.repository';
import { sendRole } from 'api/repositories/users.repository';
import { ErrorResponse } from 'entities/Error';

import AuthButton from '../../components/AuthButton';
import AuthInput from '../../components/AuthInput';

const STUDENT_ROLE_ID = 4;

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const notify = useNotification();
  const { handleCommonError } = useHandleError();
  const [query] = useSearchParams();
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const token = query.get('token');

  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const passwordSuffix = useMemo(() => {
    if (passwordVisible) {
      return <CloseEyeIcon className="cursor-pointer" onClick={() => setPasswordVisible(false)} />;
    }
    return <OpenEyeIcon className="cursor-pointer" onClick={() => setPasswordVisible(true)} />;
  }, [passwordVisible]);

  const onSubmit = async (data: Record<string, string>) => {
    if (!token) return;
    try {
      setIsLoading(true);
      const response = await loginStudent(data.email, data.password, token);
      dispatch(authSlice.actions.login(response));
      const responseSelectRole = await sendRole(STUDENT_ROLE_ID);
      dispatch(authSlice.actions.updateUser(responseSelectRole));
      setTimeout(() => navigate('/student'), 300);
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      if (error.response?.status === 400) {
        notify.error(t('error.wrongAuth'));
      } else {
        handleCommonError(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!token) {
      navigate('*');
    } else {
      checkStudentToken(token)
        .then((res) => {
          if (!res.success) navigate('*');
        })
        .catch(() => navigate('*'));
    }
  }, []);

  return (
    <div className="w-full flex flex-col justify-center items-center gap-y-8">
      <LMSTitle tag="h2" className="text-current text-4xl font-normal sm:text-44px">
        Авторизация
      </LMSTitle>

      <form
        className="max-w-[500px] w-full p-8 rounded-[20px] flex flex-col gap-y-[36px] theme-student-card sm:p-[40px] md:p-[60px]"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="email"
          rules={{
            required: { message: t('error.required'), value: true },
          }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <AuthInput
              label="Имя пользователя"
              ref={ref}
              name={name}
              value={value}
              required
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          rules={{
            required: { message: t('error.required'), value: true },
            minLength: { message: t('error.minLength', { len: 6 }), value: 6 },
          }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <AuthInput
              label="Пароль"
              type={passwordVisible ? 'text' : 'password'}
              ref={ref}
              name={name}
              value={value}
              required
              suffix={passwordSuffix}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
            />
          )}
        />
        {/* <p className="-mt-5">
            <Link to="/auth/reset-password" className="text-sm text-blue">
              Забыли пароль?
            </Link>
          </p> */}
        <AuthButton loading={isLoading}>{t('action.signIn')}</AuthButton>

        <p className="-mt-5 text-white text-base text-center font-normal">
          У вас нет аккаунта?
          <Link
            to={`/auth/student-register?token=${token}`}
            className="pl-1 text-base font-semibold text-blue"
          >
            Зарегистрироваться
          </Link>
        </p>
      </form>
    </div>
  );
};

export default Login;
