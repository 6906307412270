import request from 'api/axios-instance';

import { Group, GroupCourse, GroupParams, GroupStudent } from '../../entities/Group';
import { GroupCourseDao, GroupDao, GroupStudentDao } from '../dao/course-group.dao';

export const fetchGroups = (params: Record<string, string | number | null>) =>
  request.get('v2/coach/groups', { params }).then((res) => {
    const groups: Group[] = res.data.data.map(mapGroupDaoToEntity);
    return { groups, lastPage: res.data.meta.last_page as number };
  });

export const fetchGroupById = (id: number) =>
  request.get(`v2/coach/groups/${id}`).then((res) => mapGroupDaoToEntity(res.data.data));

export const tokenGenerate = (id: number) =>
  request.get(`v2/coach/groups/${id}/token/generate`).then((res) => {
    const token: string = res.data.data.token;
    return token;
  });

export const tokenRegenerate = (id: number) =>
  request.get(`v2/coach/groups/${id}/token/regenerate`).then((res) => {
    const token: string = res.data.data.token;
    return token;
  });

export const createGroup = (data: GroupParams) => {
  return request.post('v2/coach/groups', data).then((res) => res.data);
};

export const editGroup = (id: number, data: GroupParams) => {
  return request.post(`v2/coach/groups/${id}`, data).then((res) => res.data);
};

export const deleteGroup = (id: number) =>
  request.delete(`v2/coach/groups/${id}`).then((res) => res.data);

function mapGroupDaoToEntity(group: GroupDao): Group {
  return {
    id: group.id,
    name: group.name,
    token: group.token || null,
    course: mapGroupCourseDaoToEntity(group.course),
    status: group.status || null,
    coordinator: group.coordinator ? mapGroupStudentDaoToEntity(group.coordinator) : null,
    students: group.students.map(mapGroupStudentDaoToEntity),
  };
}

function mapGroupCourseDaoToEntity(course: GroupCourseDao): GroupCourse {
  return {
    id: course.id,
    name: course.name,
    price: course.price,
    dateFrom: course.date_from,
    dateTo: course.date_to,
    currency: course.currency,
    imageUrl: course.image_url,
    courseType: course.course_type,
    studyType: course.study_type,
  };
}

function mapGroupStudentDaoToEntity(student: GroupStudentDao): GroupStudent {
  return {
    id: student.id,
    name: student.name,
  };
}
