import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as BookIcon } from 'assets/icons/book.svg';
import { ReactComponent as FaqIcon } from 'assets/icons/faq.svg';
import { ReactComponent as LibraryIcon } from 'assets/icons/library.svg';
import { ReactComponent as ListIcon } from 'assets/icons/list.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user-full.svg';
import { ReactComponent as LogoIcon } from 'assets/images/logo.svg';
import ChangeRoleButton from 'components/ChangeRoleButton';
import LMSDropdown, { DropdownOption } from 'components/LMSDropdown';
import { useAppSelector } from 'hooks/redux';

import NotificationDropdown from '../NotificationDropdown';

const Header = () => {
  const location = useLocation();
  const { user } = useAppSelector((state) => state.auth);

  const menuOptions: DropdownOption[] = [
    {
      id: 1,
      title: 'Мое обучение',
      icon: <BookIcon />,
      path: '/student',
    },
    {
      id: 2,
      title: 'Библиотека',
      icon: <LibraryIcon className="w-5 h-5" />,
      path: '/student/library',
    },
    {
      id: 3,
      title: 'Все курсы',
      icon: <ListIcon className="w-5 h-5" />,
      path: '/student/courses',
    },
    {
      id: 4,
      title: 'Вопрос-ответ',
      icon: <FaqIcon className="w-5 h-5" />,
      path: '/student/faq',
    },
    // {
    //   id: 5,
    //   title: 'Eduson',
    //   icon: <BookIcon />,
    //   path: '/student/eduson',
    // },
  ];

  const [selectedMenu, setSelectedMenu] = useState<DropdownOption>(menuOptions[0]);

  useEffect(() => {
    const option = menuOptions.find((o) => o.path === location.pathname);
    if (option) {
      setSelectedMenu(option);
    }
  }, [location.pathname]);

  return (
    <div className="w-full theme-text py-7 px-4 border-b border-white/25">
      <div className="flex items-center justify-between mx-auto">
        <NavLink to="/student" className="text-current">
          {user?.business?.name ? (
            <span className="text-3xl text-current text-center font-semibold">
              {user?.business?.name}
            </span>
          ) : (
            <LogoIcon />
          )}
        </NavLink>
        <div className="flex items-center space-x-2 md:space-x-5">
          <LMSDropdown options={menuOptions} position="left">
            <div className="flex items-center space-x-2.5 p-0 md:p-1.5 md:pr-4 theme-default-button rounded-full text-current">
              <div className="flex items-center justify-center w-[42px] h-[42px] bg-white rounded-full theme-default-icon theme-border-icon">
                {selectedMenu.icon}
              </div>
              <p className="hidden md:block">{selectedMenu.title}</p>
            </div>
          </LMSDropdown>
          <NavLink
            to="/student/profile"
            className="flex items-center space-x-2.5 p-0 md:p-1.5 md:pr-4 theme-default-button rounded-full text-current"
          >
            <div className="flex items-center justify-center w-[42px] h-[42px] bg-white rounded-full text-black theme-default-icon theme-border-icon">
              <UserIcon />
            </div>
            <p className="hidden md:block max-w-[180px] theme-text--default truncate">
              {user?.fio}
            </p>
          </NavLink>
          <ChangeRoleButton />
          <NotificationDropdown leftPosition="right-0" />
        </div>
      </div>
    </div>
  );
};

export default Header;
