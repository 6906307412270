import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import BaseModal from 'components/BaseModal';
import LMSButton from 'components/LMSButton';
import LMSInput from 'components/LMSInput';
import LMSTitle from 'components/LMSTitle';
import { useNotification } from 'hooks/notification';
import { ErrorResponse } from 'entities/Error';

import {
  changeOrderModules,
  createModule,
  deleteModule,
  editModule,
} from '../../api/repository/modules';
import { ICourseModule } from '../../entities/Course';

interface Props {
  modules: ICourseModule[];
  onClose: () => void;
}

const CreateModulesModal: React.FC<Props> = ({ modules: oldModules, onClose }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const notify = useNotification();

  const [loading, setLoading] = useState<boolean>(false);
  const [modules, setModules] = useState<ICourseModule[]>(oldModules);

  const onModulesOrderChange = (result: DropResult) => {
    if (!result.destination) return;

    const newModules = [...modules];
    const [reorderedModule] = newModules.splice(result.source.index, 1);
    newModules.splice(result.destination.index, 0, reorderedModule);
    setModules(newModules);
  };

  const onModuleTitleChange = (newTitle: string, moduleIndex: number) => {
    const newModules = modules.map((module: ICourseModule, index: number) => {
      if (moduleIndex === index) {
        return {
          ...module,
          title: newTitle,
        };
      }
      return module;
    });
    setModules(newModules);
  };

  const addNewModule = () => {
    const module: ICourseModule = {
      id: Date.now(),
      title: '',
    };

    setModules((prev) => [...prev, module]);
  };

  const saveModules = async () => {
    setLoading(true);

    const modulesForDelete = oldModules.filter((oldModule) => {
      if (modules.some((module) => module.id === oldModule.id)) {
        return false;
      }
      return true;
    });

    const modulesForCreate = modules.filter((module) => {
      if (oldModules.some((oldModule) => oldModule.id === module.id)) {
        return false;
      }
      return true;
    });

    const modulesForEdit: ICourseModule[] = [];
    modules.forEach((module) => {
      oldModules.forEach((oldModule) => {
        if (oldModule.id === module.id) {
          module.title !== oldModule.title && modulesForEdit.push(module);
        }
      });
    });

    try {
      if (id) {
        await Promise.all([
          ...modulesForDelete.map((module) => deleteModule(module.id)),
          ...modulesForCreate.map((module) => createModule({ ...module, course_id: id })),
          ...modulesForEdit.map((module) =>
            editModule({ ...module, title: module.title, course_id: id })
          ),
        ]);
        // await changeOrderModules(+id, { order: modules.map((module) => ({ id: module.id })) });

        navigate('/hr/courses');
        notify.success('Изменения успешно сохранены');
      }
    } catch (err) {
      const error = err as ErrorResponse;
      notify.error(String(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal wrapperClassName="sm:!max-w-max" onClose={onClose}>
      <div className="flex flex-col space-y-10 min-w-full lg:min-w-[553px] max-h-[80vh] overflow-y-auto p-0 sm:p-4">
        <LMSTitle tag="h2">Создать модуль к курсу</LMSTitle>

        <div className="flex flex-col space-y-6">
          <DragDropContext onDragEnd={onModulesOrderChange}>
            <Droppable droppableId="modulesList">
              {(outerProvided) => (
                <div
                  {...outerProvided.droppableProps}
                  ref={outerProvided.innerRef}
                  className="flex flex-col space-y-4"
                >
                  {modules.map((module, index) => (
                    <Draggable key={module.id} draggableId={module.id.toString()} index={index}>
                      {(provided) => (
                        <div {...provided.draggableProps} ref={provided.innerRef}>
                          <div className="flex flex-row items-center space-x-4">
                            <button
                              {...provided.dragHandleProps}
                              className="bg-[#F2F2F2] rounded-lg p-2"
                            >
                              <DragIcon />
                            </button>
                            <div className="w-full bg-[#F2F2F2] rounded-xl">
                              <LMSInput
                                name="name"
                                label={`Модуль №${index + 1}`}
                                value={module.title}
                                placeholder="Введите название модуля"
                                onChange={(e) => onModuleTitleChange(e, index)}
                                className="!text-black"
                              />
                            </div>
                            <button
                              className="w-[48px] h-[48px] min-w-[48px] bg-[#F2F2F2] rounded-full flex items-center justify-center p-2"
                              onClick={() =>
                                setModules((prev) => prev.filter((q) => q.id !== module.id))
                              }
                            >
                              <TrashIcon />
                            </button>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}

                  {outerProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <button
            className="flex items-center justify-center space-x-2 w-full py-4 rounded-lg bg-[#F4F9FF] text-blue"
            onClick={addNewModule}
          >
            <span className="text-2xl">+</span>
            <span>Создать модуль</span>
          </button>
        </div>

        <div className="flex flex-row justify-end">
          <LMSButton loading={loading} onClick={saveModules}>
            СОХРАНИТЬ
          </LMSButton>
        </div>
      </div>
    </BaseModal>
  );
};

export default CreateModulesModal;
