import React from 'react';
import { ReactComponent as LinkIcon } from 'assets/icons/link-2.svg';
import { ReactComponent as AddProfileIcon } from 'assets/icons/profile-add.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search-status.svg';
import BaseModal from 'components/BaseModal';
import LMSButton from 'components/LMSButton';
import LMSTitle from 'components/LMSTitle';

interface Props {
  onClose: () => void;
  addUserManually: () => void;
  openModalAddUserByLink: () => void;
  openModalAddUserBySearch: () => void;
}

const AddUserModal: React.FC<Props> = ({
  onClose,
  addUserManually,
  openModalAddUserByLink,
  openModalAddUserBySearch,
}) => (
  <BaseModal wrapperClassName="sm:!max-w-max" onClose={onClose}>
    <div className="flex flex-col space-y-6 min-w-full lg:min-w-[436px] max-w-[436px] p-6 lg:p-10">
      <LMSTitle tag="h2">Добавить пользователя</LMSTitle>
      <div className="flex flex-col space-y-4">
        <LMSButton
          colorType="light"
          onClick={() => {
            onClose();
            addUserManually();
          }}
          prefix={<AddProfileIcon />}
          className="border !rounded-2xl !border-lightGray !justify-start"
        >
          Вручную или из файла
        </LMSButton>
        <LMSButton
          colorType="light"
          onClick={() => {
            onClose();
            openModalAddUserByLink();
          }}
          prefix={<LinkIcon />}
          className="border !rounded-2xl !border-lightGray !justify-start"
        >
          Доступ по ссылке
        </LMSButton>
        <LMSButton
          colorType="light"
          onClick={() => {
            onClose();
            openModalAddUserBySearch();
          }}
          prefix={<SearchIcon />}
          className="border !rounded-2xl !border-lightGray !justify-start hidden"
        >
          Поиск пользователя на платформе
        </LMSButton>
      </div>
    </div>
  </BaseModal>
);

export default AddUserModal;
