import { NavLink } from 'react-router-dom';
import { ReactComponent as BookIcon } from 'assets/icons/couch/book.svg';
import { ReactComponent as EditIcon } from 'assets/icons/couch/cedit.svg';
import { ReactComponent as CourseIcon } from 'assets/icons/couch/course.svg';
import { ReactComponent as TextIcon } from 'assets/icons/couch/document-text.svg';
import { ReactComponent as JournalIcon } from 'assets/icons/couch/journal.svg';
import { ReactComponent as NoutIcon } from 'assets/icons/couch/note-text.svg';
import { ReactComponent as PasswordIcon } from 'assets/icons/couch/password.svg';
//menu icons
import { ReactComponent as TaskIcon } from 'assets/icons/couch/task.svg';
// ----- end icons
import { ReactComponent as DividerIcon } from 'assets/icons/divider.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import Avatar from 'components/Avatar';
import LMSTitle from 'components/LMSTitle';
import { authSlice } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

const SideBar = () => {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const navItems = [
    {
      id: 0,
      title: 'Расписание занятий',
      link: '/coach/schedule',
      icon: <NoutIcon />,
    },
    // {
    //   id: 1,
    //   title: 'Курсы',
    //   link: '/coach/courses',
    //   icon: course,
    // },
    {
      id: 2,
      title: 'Домашнее задание',
      link: '/coach/homeworks',
      icon: <BookIcon />,
    },
    {
      id: 3,
      title: 'Уроки',
      link: '/coach/lessons',
      icon: <TextIcon />,
    },
    {
      id: 4,
      title: 'Тесты',
      link: '/coach/tests',
      icon: <TaskIcon />,
    },
    // {
    //   id: 9,
    //   title: 'Проверка Домашних заданий',
    //   link: '/coach/check-hometask',
    //   icon: <BookIcon />,
    // },
    {
      id: 5,
      title: 'Проверки заданий',
      link: '/coach/check-assignments/homeworks',
      icon: <BookIcon />,
    },
    {
      id: 6,
      title: 'Журнал посещаемости',
      link: '/coach/journal',
      icon: <JournalIcon />,
    },
    {
      id: 7,
      title: 'Редактировать профиль',
      link: '/coach/edit-profile',
      icon: <EditIcon />,
    },
    // {
    //   id: 8,
    //   title: 'Пароль',
    //   link: '/coach/change-password',
    //   icon: <PasswordIcon />,
    // },
  ];

  const logout = () => {
    dispatch(authSlice.actions.logout());
  };

  return (
    <div className="w-full h-full flex flex-col theme-student-card rounded-3xl">
      <div className="w-full h-fit flex flex-row justify-between py-3 px-3 border-b theme-border--light-grey bg-transparent lg:flex-col lg:pt-[40px] lg:border-b-0">
        <div className="text-3xl text-current text-center font-semibold select-none w-full flex justify-center items-center">
          {user?.business?.name ? <span>{user?.business?.name}</span> : 'CTE PLATFORM'}
        </div>
        <DividerIcon className="hidden mt-[30px] mx-auto w-[calc(100%-12px)] lg:block" />
      </div>
      <div className="flex flex-col items-center gap-x-4 py-6 px-4 hidden">
        <div className="w-[90px] h-[90px] 2xl:w-[120px] 2xl:h-[120px]">
          <Avatar image={user?.avatar} />
        </div>
        {user?.fio && (
          <LMSTitle tag={'h3'} className="text-center text-current text-xl font-semibold lg:pt-5 ">
            {user?.fio}
          </LMSTitle>
        )}
      </div>
      <div className="flex flex-col overflow-y-scroll">
        <nav className="w-full h-fit">
          <ul>
            {navItems &&
              navItems.map((el) => {
                return (
                  <li
                    key={el?.id}
                    className="theme-border--light-grey theme-background--hover border-t cursor-pointer last-of-type:border-b-none"
                  >
                    <NavLink
                      to={`${el?.link}`}
                      className={({ isActive }) => {
                        // border-blue border-r-[2px] for active
                        return `
                          flex flex-row items-center gap-x-5 px-4 py-5 lg:px-[30px] 2xl:text-[22px] theme-icon-stroke-sidebar
                          ${isActive ? 'theme-background--grey' : ''}
                        `;
                      }}
                    >
                      {el?.icon}
                      <span className="theme-text--default"> {el?.title}</span>
                    </NavLink>
                  </li>
                );
              })}
          </ul>
        </nav>
      </div>
      <button
        onClick={() => logout()}
        className="w-full flex flex-row items-center justify-start gap-x-5 mt-auto p-[26px] text-xl font-medium border-t theme-border--light-grey theme-icon-stroke-sidebar"
      >
        <LogoutIcon />
        <span className="theme-text--default"> Выйти из аккаунта</span>
      </button>
    </div>
  );
};

export default SideBar;
