import React, { useEffect, useLayoutEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as BookIcon } from 'assets/icons/book.svg';
import { ReactComponent as BurgerIcon } from 'assets/icons/burger.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as FaqIcon } from 'assets/icons/faq.svg';
import { ReactComponent as LibraryIcon } from 'assets/icons/library.svg';
import { ReactComponent as ListIcon } from 'assets/icons/list.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user-full.svg';
import { ReactComponent as LogoIcon } from 'assets/images/logo.svg';
import BaseImage from 'components/BaseImage';
import LMSDropdown, { DropdownOption } from 'components/LMSDropdown';
import { useAppSelector } from 'hooks/redux';
import { useWindowSize } from 'hooks/window-size';

import NotificationDropdown from '../NotificationDropdown';

interface Props {
  isSidebarOpen: boolean;
  onSidebarOpen: (val: boolean) => void;
}

const ProfileHeader: React.FC<Props> = ({ isSidebarOpen, onSidebarOpen }) => {
  const device = useWindowSize();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAppSelector((state) => state.auth);

  const menuOptions: DropdownOption[] = [
    {
      id: 1,
      title: 'Мое обучение',
      icon: <BookIcon />,
      path: '/student',
    },
    {
      id: 2,
      title: 'Библиотека',
      icon: <LibraryIcon className="w-5 h-5" />,
      path: '/student/library',
    },
    {
      id: 3,
      title: 'Все курсы',
      icon: <ListIcon className="w-5 h-5" />,
      path: '/student/courses',
    },
    {
      id: 4,
      title: 'Вопрос-ответ',
      icon: <FaqIcon className="w-5 h-5" />,
      path: '/student/faq',
    },
  ];

  const [selectedMenu, setSelectedMenu] = useState<DropdownOption>(menuOptions[0]);

  useEffect(() => {
    const option = menuOptions.find((o) => o.path === location.pathname);
    if (option) {
      setSelectedMenu(option);
    }
  }, [location.pathname]);

  useLayoutEffect(() => {
    if (device !== 'desktop' && isSidebarOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [device, isSidebarOpen]);

  return (
    <div className="w-full text-current pb-7">
      <div className="flex items-center justify-between mx-auto">
        <div className="flex items-center space-x-3">
          {/* TODO: заменить на иконку с бизнеса */}
          <NavLink to="/student" className="w-12 lg:hidden">
            <BaseImage image={user?.business?.logo} />
          </NavLink>
          <button
            className="hidden lg:flex items-center space-x-2 text-current rounded-full p-4"
            onClick={() => navigate(-1)}
          >
            <ArrowLeftIcon />
            <span>Назад</span>
          </button>
          <LMSDropdown options={menuOptions} position="left">
            <div className="flex items-center gap-x-4 p-2 lg:pr-5 rounded-full theme-default-button">
              <div className="w-[40px] h-[40px] flex items-center justify-center rounded-full theme-default-icon theme-border-icon">
                {selectedMenu.icon}
              </div>
              <p className="theme-text--default">{selectedMenu.title}</p>
            </div>
          </LMSDropdown>
        </div>
        <div className="flex items-center space-x-5">
          <NavLink
            to="/student/profile"
            className="hidden lg:flex items-center space-x-2.5 p-0 md:p-1.5 md:pr-4 theme-default-button rounded-full"
          >
            <div className="flex items-center justify-center w-[42px] h-[42px] theme-default-icon theme-border-icon rounded-full text-black">
              <UserIcon />
            </div>
            <p className="hidden md:block max-w-[180px] theme-text--default truncate">
              {user?.fio}
            </p>
          </NavLink>
          <NotificationDropdown />
          {isSidebarOpen ? (
            <button className="block lg:hidden" onClick={() => onSidebarOpen(false)}>
              <CloseIcon className="w-6 h-6" />
            </button>
          ) : (
            <button className="block lg:hidden" onClick={() => onSidebarOpen(true)}>
              <BurgerIcon className="w-6 h-6" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
