import React, { useMemo } from 'react';
import { ReactComponent as SpinnerIcon } from 'assets/icons/spinner.svg';

export type ButtonType = 'button' | 'submit' | 'reset';
export type ButtonColorType = 'main' | 'light';
export type ButtonVariant = 'solid' | 'text';

type ButtonProps = {
  children: React.ReactNode;
  className?: string;
  type?: ButtonType;
  isRed?: boolean;
  onClick?: () => void;
  variant?: ButtonVariant;
  colorType?: ButtonColorType;
  disabled?: boolean;
  loading?: boolean;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
};

const LMSButton: React.FC<ButtonProps> = ({
  type = 'button',
  className = '',
  onClick = () => {},
  variant = 'solid',
  colorType = 'main',
  disabled = false,
  loading = false,
  isRed = false,
  children,
  suffix,
  prefix,
}) => {
  const btnClass = useMemo(
    () =>
      `
        flex items-center justify-center space-x-2 text-sm z-10 md:z-0 md:text-base px-3 md:px-[25px] py-2 md:py-3.5 rounded-full
        ${disabled ? 'bg-white/20 text-[#B1B1B1] pointer-events-none' : ''}
        ${variant === 'text' ? 'bg-transparent' : ''}
        ${isRed ? 'bg-red-500' : 'theme-default-button--active'}
        ${
          loading
            ? 'relative text-transparent'
            : colorType === 'light'
            ? 'bg-white text-black'
            : 'text-white'
        }
        ${className}
      `,
    [variant, colorType, loading, disabled]
  );
  return (
    <button className={btnClass} type={type} disabled={disabled || loading} onClick={onClick}>
      {loading ? (
        <span className="absolute inset-0 flex items-center justify-center">
          <SpinnerIcon className="animate-spin" />
        </span>
      ) : null}
      <span>{prefix ? prefix : null}</span>
      <span>{children}</span>
      <span>{suffix ? suffix : null}</span>
    </button>
  );
};

export default LMSButton;
