import React from 'react';
import { useTranslation } from 'react-i18next';

import BgCircles from '../bgCircles';

import classes from './hero.module.scss';

const LandingHero = () => {
  const { t } = useTranslation();

  return (
    <div id="hero" className="w-full h-full bg-[#05051E] overflow-hidden">
      <div className="max-w-[1440px] w-full mx-auto flex flex-col justify-center items-center py-[86px] md:py-[126px] relative">
        <div className="w-full flex flex-col md:flex-row gap-10 md:gap-40 items-center md:pl-20 mb-20">
          <section className="w-full flex flex-col items-center px-8 md:p-0 md:max-w-[600px]">
            {/* <button className="bg-[#05051E] border border-[#3c3c4f] rounded-[68px] px-8 py-[14px] mb-[58px] z-10">
          <span className={`${classes.demo_btn_text} text-xl font-medium`}>
            {t('landing.hero.try')}
          </span>
        </button> */}
            <h1 className="text-[42px] md:text-[32px] max-w-[270px] md:max-w-none text-center md:text-left md:text-[62px] flex flex-col mb-[44px] z-10 leading-tight">
              <span className="text-white">{t('landing.hero.titleTop')}</span>
              <span className={`${classes.demo_btn_text}`}>{t('landing.hero.titleBottom')}</span>
            </h1>
            <p className="text-[#CFD1E4] text-center md:text-left text-xl max-w-[668px] tracking-[-0.4px] mb-[52px] z-10">
              {t('landing.hero.subtitle')}
            </p>
            {/* <button className={`${classes.try_btn} mb-[86px] z-10`}>{t('landing.hero.btn')}</button> */}
          </section>
          <div className="relative w-full flex justify-center md:justify-start">
            <img
              src={require('assets/images/mob-image.png')}
              width="100%"
              height="100%"
              className="relative z-10 max-w-[314px] max-h-[642px]"
              alt="презентация платформы"
            />

            <img
              src={require('assets/images/hero.png')}
              width="100%"
              height="100%"
              className="absolute top-[50%] translate-y-[-50%] md:left-[10%] h-full max-h-[570px] object-cover"
              alt="презентация платформы"
            />
          </div>
        </div>

        {/* <BgCircles /> */}
      </div>

      <div id="clients" className="mb-40">
        <section className="mb-16 flex gap-10 items-center justify-center">
          <img className="hidden md:block" src={require('assets/images/line.png')} alt="" />
          <h2 className="text-white/50 text-[32px] md:text-[20px] text-center">Наши клиенты</h2>
          <img
            className="rotate-180 hidden md:block"
            src={require('assets/images/line.png')}
            alt=""
          />
        </section>
        <div className="w-full flex flex-col md:flex-row gap-16 justify-center items-center">
          <img src={require('assets/images/avenues.png')} alt="" />
          <img src={require('assets/images/unis.png')} alt="" />
          <img src={require('assets/images/bi.png')} alt="" />
          <img src={require('assets/images/test.png')} alt="" />
        </div>
      </div>
    </div>
  );
};

export default LandingHero;
