import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import Modules from 'modules/coach/components/courses/Modules';
import Users from 'modules/coach/components/courses/Users';

const CoachCourseDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState<number>(0);

  const goBack = () => {
    navigate('/coach/courses');
  };

  const switchTab = (tab: number) => {
    setActiveTab(tab);
  };

  const students = [
    {
      id: 0,
      avatar: '',
      name: 'Нурдаулет Куандык',
      email: 'student001@gmail.com',
      wasOnline: '1 час назад',
    },
    {
      id: 1,
      avatar: '',
      name: 'Нурдаулет Куандык',
      email: 'student001@gmail.com',
      wasOnline: '3 часа назад',
    },
    {
      id: 2,
      avatar: '',
      name: 'Нурдаулет Куандык',
      email: 'student001@gmail.com',
      wasOnline: '20 мин. назад',
    },
    {
      id: 3,
      avatar: '',
      name: 'Нурдаулет Куандык',
      email: 'student001@gmail.com',
      wasOnline: '1 мес.назад',
    },
  ];

  const modules = [
    {
      id: 0,
      moduleName: 'UI testing',
      lessons: [
        {
          id: 0,
          name: 'Тестирование пользовательского интерфейса',
          homeworks: [
            {
              id: 0,
              name: 'Тестирование пользовательского интерфейса',
            },
          ],
          tests: [
            {
              id: 1,
              name: 'Тестирование пользовательского интерфейса',
            },
          ],
        },
      ],
    },
    {
      id: 1,
      moduleName: 'Тестирование функционала',
      lessons: [
        {
          id: 0,
          name: 'Тестирование пользовательского интерфейса',
          homeworks: [
            {
              id: 0,
              name: 'Тестирование пользовательского интерфейса',
            },
          ],
          tests: [
            {
              id: 1,
              name: 'Тестирование пользовательского интерфейса',
            },
          ],
        },
      ],
    },
  ];

  return (
    <div className="w-full h-full flex flex-col pt-10 lg:pt-6">
      <button
        type="button"
        onClick={() => goBack()}
        className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-white text-[14px] font-medium bg-white bg-opacity-20 rounded-[36px] border-none outline-none uppercase"
      >
        <ArrowBack />
        назад
      </button>
      <div className="flex flex-col pt-20">
        <div className="grid grid-cols-2 grid-rows-2 gap-7">
          <div className="grid place-content-start">
            <span className="text-base font-medium text-white opacity-40">Название курса</span>
            <h5 className="text-2xl font-semibold text-white">BIG Engineer Project</h5>
          </div>
          <div className="grid place-content-end">
            <span className="text-base font-medium text-white opacity-40">Период обучения</span>
            <span className="text-xl font-normal text-white">22.10.2021-20.12.2021</span>
          </div>
          <div className="grid place-content-start">
            <span className="text-base font-medium text-white opacity-40">Название группы</span>
            <h5 className="text-2xl font-semibold text-white">SPR503</h5>
          </div>
          <div className="grid place-content-end">
            <div className="flex flex-row justify-between items-center gap-x-4 py-1.5 px-3.5 text-base font-medium text-white border rounded-full cursor-pointer">
              Статистика курса
              <SettingsIcon />
            </div>
          </div>
        </div>
        <div className="pt-16">
          <div className="flex flex-row">
            {['Модули', 'Пользователи'].map((el, idx) => (
              <div
                key={idx}
                className={`${
                  activeTab === idx ? 'bg-[#0c1231]' : 'bg-transparent border-t border-l border-r'
                } py-4 px-7 text-lg text-white rounded-tr-xl rounded-tl-xl cursor-pointer`}
                onClick={() => switchTab(idx)}
              >
                {el.toString()}
              </div>
            ))}
          </div>
          <div className="w-full h-full flex flex-col">
            {activeTab === 0 ? <Modules modules={modules} /> : <Users students={students} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachCourseDetail;
