import { useEffect, useMemo, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as BookIcon } from 'assets/icons/book.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import { ReactComponent as EditIcon } from 'assets/icons/couch/cedit.svg';
import { ArcElement, Chart as ChartJS } from 'chart.js';
import HomeWorkMessageModal from 'components/HomeWorkMessageModal';
import Loader from 'components/Loader';
import MessageCard from 'components/MessageCard';
import { fetchStudentInfo } from 'modules/coach/api/repository/home-task-student';
import { fetchHomeWorkMark } from 'modules/coach/api/repository/homework-mark';
import { fetchHomeTask } from 'modules/coach/api/repository/homework-task';
import { fetchStudentMessages } from 'modules/coach/api/repository/messages';
import FileDownloadButton from 'modules/coach/components/DownLoadButton';
import HomeWorkMarkModal from 'modules/coach/components/HomeWorkMarkModal';
import { IHomeTask } from 'modules/coach/entities/HomeTaskEntity';
import { IHomeTaskStudentInfo } from 'modules/coach/entities/HomeWorkTaskStudent';
import { IStudentMessages } from 'modules/coach/entities/StudentMessages';
import { useNotification } from 'hooks/notification';

const CheckHomeWork = () => {
  const location = useLocation();
  const { homeworkId } = useParams();
  const [query] = useSearchParams();
  const [homeTask, setHomeTask] = useState<IHomeTask>();
  const [homeworkMark, setHomeworkMark] = useState<number>();
  const [student, setStudent] = useState<IHomeTaskStudentInfo>();
  const [studentMessages, setStudentMessages] = useState<IStudentMessages[]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenRateModal, setIsOpenRareModal] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(true);
  const [isCoach, setCoach] = useState(true);
  const [studentId, setStudentId] = useState<number>();
  const [calendarId, setCalendarId] = useState<number>();
  const [lessonId, setLessonId] = useState<number>();
  const navigate = useNavigate();
  const notify = useNotification();

  ChartJS.register(ArcElement);

  useEffect(() => {
    const calendarId = Number(query.get('calendar_id'));
    const studentId = Number(query.get('student_id'));
    const lessonId = Number(query.get('lesson_id'));

    setStudentId(studentId);
    setCalendarId(calendarId);
    setLessonId(lessonId);
  }, []);

  useEffect(() => {
    setCoach(location.pathname.includes('coach'));
  }, [location.pathname]);

  useEffect(() => {
    if (!homeworkId || !lessonId || !studentId || !calendarId) return;

    Promise.all([
      fetchStudentInfo(Number(studentId), Number(lessonId)),
      fetchHomeTask(Number(homeworkId), studentId, calendarId),
      // fetchStudentMessages(Number(studentId), Number(lessonId)),
      // fetchHomeWorkMark(Number(lessonId), Number(studentId)),
    ])
      .then(([studentInfo, homeTask]) => {
        setStudent(studentInfo);
        setHomeTask(homeTask);
        setStudentMessages(homeTask.hometaskMessages);
        homeTask?.mark && setHomeworkMark(Number(homeTask.mark));
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [homeworkId, lessonId, calendarId, studentId]);

  const toggleModal = () => {
    setIsOpenModal((prev) => !prev);
  };

  const handleRate = () => {
    setIsOpenRareModal((prev) => !prev);
  };

  const goBack = () => navigate(-1);

  const chartData = useMemo(() => {
    return {
      datasets: [
        {
          data: [homeworkMark, homeworkMark ? 100 - homeworkMark : 0],
          backgroundColor: [
            homeworkMark && homeworkMark < 50 ? '#FF0000' : '#58DB66',
            'rgba(88,219,102,0.2)',
          ],
          borderWidth: 0,
          cutout: String(homeworkMark),
          borderRadius: 20,
          offset: 6,
        },
      ],
    };
  }, [homeworkMark]);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div className="w-full h-full flex flex-col mt-24 lg:mt-4">
      <button
        type="button"
        onClick={() => goBack()}
        className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 theme-text--default text-[14px] font-medium bg-white/60 rounded-[36px] border-none outline-none uppercase"
      >
        <ArrowBack />
        назад
      </button>
      <div className="flex flex-col mt-10">
        <div className="flex flex-row justify-between items-baseline">
          <h1 className="pb-10 text-current text-3xl w-full">{homeTask?.courseName}</h1>
          {homeTask?.deadline && (
            <div className="w-full flex flex-row items-center justify-center gap-x-3 py-3 px-4 text-current text-lg rounded-full theme-coach-background bg-opacity-50 max-w-[340px]">
              <CalendarIcon className="text-[#58DB66]" />
              Сдать до: {homeTask?.deadline.slice(0, 16)}
            </div>
          )}
        </div>
        <div className="flex flex-col px-10 py-5 theme-coach-background--gn rounded-2xl">
          {isLoading ? (
            <div className="my-10">
              <Loader theme="dark" />
            </div>
          ) : (
            <div className="flex flex-col gap-y-3 lg:flex-row lg:justify-between">
              <div className="flex flex-row items-center gap-x-3 text-[#26A7EF] text-lg">
                Урок <p className="text-current text-base hyphens-auto">{homeTask?.name}</p>
              </div>
              <div className="flex flex-col gap-y-2 gap-x-4 text-[#26A7EF] text-base">
                Студент
                <div className="flex flex-row items-center gap-3 text-current">
                  <img
                    src={student?.student?.avatar as string}
                    className="w-10 h-10 rounded-full"
                    alt={student?.student.fio as string}
                  />{' '}
                  {student?.student?.fio}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col mt-10 p-10 theme-coach-background--gn rounded-[40px]">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-x-4 text-current text-lg">
              <BookIcon className="text-blue" /> Домашнее задание
            </div>
            {/* <div
              onClick={() => redirectToEdit()}
              className="flex flex-row items-top gap-3 cursor-pointer group"
            >
              <span className="hidden text-current group-hover:block">редактировать</span>
              <div className="p-2 border rounded group-hover:text-[blue] hover:border-[#26A7EF]">
                <EditIcon />
              </div>
            </div> */}
          </div>
          <div
            className="h-fit py-10 theme-coach-homework-text"
            dangerouslySetInnerHTML={{ __html: homeTask?.description || '' }}
          ></div>
        </div>

        {homeTask?.fileUrl?.length || homeTask?.videoUrl?.length ? (
          <div className="flex flex-col gap-6 pt-6 text-current text-lg">
            <span className="text-center">Файлы к заданию</span>
            <div className="flex-flex-col pt-1 text-current">
              {homeTask?.fileUrl?.length ? 'Файлы:' : ''}
              {homeTask?.fileUrl?.length &&
                homeTask?.fileUrl.map((el, idx) => {
                  return (
                    <div key={idx}>
                      <div className="w-fit flex flex-row items-center gap-3">
                        <div className="flex flex-row items-center gap-2 text-[#26A7EF] cursor-pointer hyphens-auto hover:underline hover:underline-offset-4">
                          <FileDownloadButton
                            fileLink={el.downloadLink}
                            name={el.name}
                            withoutWord={true}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="flex-flex-col pt-1 text-current">
              {homeTask?.videoUrl?.length ? 'Видео файлы:' : ''}
              {homeTask?.videoUrl?.length &&
                homeTask?.videoUrl.map((el, idx) => {
                  return (
                    <div key={idx}>
                      <div className="w-fit flex flex-row items-center gap-3">
                        <div className="flex flex-row items-center gap-2 text-[#26A7EF] cursor-pointer hyphens-auto hover:underline hover:underline-offset-4">
                          <FileDownloadButton
                            fileLink={el.downloadLink}
                            name={el.name}
                            withoutWord={true}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          ''
        )}

        <div className="flex flex-col gap-y-10 mt-5">
          {studentMessages.length ? (
            <h4 className="pb-10 text-current text-center text-3xl">Проверка домашнего задания</h4>
          ) : (
            ''
          )}

          {homeworkMark ? (
            <div className="max-h-[300px] w-fit flex flex-row justify-between -mt-6 p-6 theme-coach-background--gn rounded-[20px]">
              <div className="w-1/2">
                <h4 className="pb-10 text-current text-2xl">
                  {!isCoach && homeworkMark < 50 && 'Вы не набрали необходимые баллы'}
                  {!isCoach && homeworkMark >= 50 && 'Задание выполнено'}
                  {isCoach && 'Оценка за задание'}
                </h4>
              </div>
              <div className="relative w-full max-w-[100px] max-h-[100px]">
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[100px] flex flex-col text-center text-current">
                  <span>{homeworkMark} из 100</span>
                </div>
                <Doughnut data={chartData} options={options} />
              </div>
            </div>
          ) : (
            ''
          )}

          {studentMessages &&
            !!studentMessages.length &&
            studentMessages.map((message, idx) => {
              return (
                <MessageCard
                  key={message.id}
                  isLast={studentMessages.length === idx + 1 && !homeworkMark}
                  isMark={homeworkMark ? true : false}
                  statusId={message?.statusId}
                  authorAvatar={message?.author?.avatar}
                  authorFio={message?.author?.fio}
                  authorId={message?.author?.id}
                  studentFio={message?.student?.fio}
                  content={message?.content}
                  lessonName={homeTask?.name}
                  createdAt={message.createdAt}
                  files={message.files}
                  onClick={toggleModal}
                  onRateClick={handleRate}
                />
              );
            })}
        </div>
      </div>

      {/* status = 2 */}
      {isOpenModal ? (
        <HomeWorkMessageModal
          studentId={Number(studentId)}
          calendarId={Number(calendarId)}
          hometaskId={Number(homeTask?.id)}
          onClose={toggleModal}
        />
      ) : null}

      {/* status = 3 */}
      {isOpenRateModal ? (
        <HomeWorkMarkModal
          studentId={Number(studentId)}
          calendarId={Number(calendarId)}
          hometaskId={Number(homeTask?.id)}
          onClose={handleRate}
        />
      ) : null}

      {/* TODO: добавить отклонение домашней работы (status=4) */}
    </div>
  );
};

export default CheckHomeWork;
