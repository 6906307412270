import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IHomeWorkForCheck } from 'modules/coach/entities/HomeWorkForCheck';

interface Props {
  homeworks: IHomeWorkForCheck[];
}

const HomeworksTable: React.FC<Props> = ({ homeworks }) => {
  const navigate = useNavigate();

  const redirectToHomework = (homework: IHomeWorkForCheck) => {
    navigate(
      `/coach/homeworks/hometask/${homework.id}?student_id=${homework.studentId}&calendar_id=${homework.calendarId}&lesson_id=${homework.lessonId}`
    );
  };

  return (
    <table className="theme-background-default-table w-full rounded-[20px] backdrop-blur-[60px] min-w-[980px] text-current overflow-x-scroll">
      <thead className="block px-8 pt-4 pb-[10px] w-full theme-background-default-table">
        <tr className="grid grid-cols-coachHomeworks w-full gap-x-8">
          <td>ФИО</td>
          <td>Название курса</td>
          <td>Группа</td>
          <td>Название д/з</td>
          <td>Дата сдачи</td>
          <td>Дата дедлайна</td>
          <td>Балл, %</td>
          <td>Cтатус</td>
        </tr>
      </thead>

      <tbody>
        {homeworks.map((homework, index) => (
          <tr
            className="grid grid-cols-coachHomeworks w-full px-8 pt-4 pb-[10px] gap-x-8 border-t theme-border--light-black hover:text-blue transition cursor-pointer"
            key={index}
            onClick={() => redirectToHomework(homework)}
          >
            <td className="w-full flex items-center">
              <span className="w-full max-w-full truncate">
                {homework.studentName || 'Нет информации'}
              </span>
            </td>
            <td className="w-full flex items-center">
              <span className="w-full max-w-full truncate">{homework.courseName}</span>
            </td>
            <td className="w-full flex items-center">
              <span className="w-full max-w-full truncate">{homework.groupName}</span>
            </td>
            <td className="w-full flex items-center">
              <span className="w-full max-w-full truncate">{homework.name}</span>
            </td>
            <td className="w-full flex items-center">
              <span className="w-full max-w-full truncate">-</span>
            </td>
            <td className="w-full flex items-center">
              <span className="w-full max-w-full truncate">
                {new Date(homework.deadline).toLocaleDateString()}
              </span>
            </td>
            <td className="w-full flex items-center">
              <span className="w-full max-w-full truncate">{homework.mark || '-'}</span>
            </td>
            <td className="w-full flex items-center">
              <span className="w-full max-w-full truncate">{homework.status.name}</span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default HomeworksTable;
