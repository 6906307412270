import request from 'api/axios-instance';

import { AttendanceLesson } from '../../entities/Attendance';
import { AttendanceStatus } from '../../entities/AttendanceStatus';
import { AttendanceLessonDao } from '../dao/Attendance.dao';
import { AttendanceStatusDao } from '../dao/AttendanceStatus.dao';

export const fetchAttendance = (params: unknown) =>
  request.get('v1/journal', { params: params }).then((res) => {
    // const lessons: AttendanceLesson[] = res.data.data.lessons.map(mapAttendanceDaoToEntity);
    return res?.data;
  });

export const fetchGroups = () =>
  request.get('v1/groups').then((res) => {
    return res?.data.data;
  });

export const fetchAttendanceStatuses = (lessonId: number) =>
  request.get('v1/attendance/statuses', { params: { lesson_id: lessonId } }).then((res) => {
    const statuses: AttendanceStatus[] = res.data.data.map(mapAttendanceStatusDaoToEntity);
    return statuses;
  });

interface AttendanceStatusDto {
  attendance: string;
  lesson_id: number;
  student_id: number;
}

export const changeStudentAttendance = (data: AttendanceStatusDto) =>
  request
    .post('v1/attendance/status', data)
    .then((res) => mapAttendanceStatusDaoToEntity(res.data.data));

function mapAttendanceDaoToEntity(lesson: AttendanceLessonDao): AttendanceLesson {
  return {
    id: lesson.lesson_id,
    name: lesson.lesson_name,
    description: lesson.lesson_description,
    group: lesson.group_name,
    course: lesson.course_name,
    position: lesson.position,
    date: lesson.date,
    timeFrom: lesson.time_from,
    timeTo: lesson.time_to,
    isLast: lesson.is_last,
    students: lesson.students,
  };
}

function mapAttendanceStatusDaoToEntity(status: AttendanceStatusDao): AttendanceStatus {
  return {
    lessonId: status.lesson_id,
    studentId: status.student_id,
    attendance: status.attendance,
    coachId: status.coach_id,
    date: status.date,
  };
}
