import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import LMSTitle from 'components/LMSTitle';
import Loader from 'components/Loader';
import { fetchCheckHomeworks } from 'modules/coach/api/repository/homeworks';
import CheckHomeWorkCard from 'modules/coach/components/CheckHomeWorkCard';
import SwitchTabs from 'modules/coach/components/courses/SwitchTabs';
import { INewAllHomeWork } from 'modules/coach/entities/HomeWorks';
import { useNotification } from 'hooks/notification';

export const emptyIcon = 'assets/images/empty-image.png';

const CheckHomeWorkForTest = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [homeWorks, setHomeWorks] = useState<INewAllHomeWork[]>([]);
  const [isLoading, setLoading] = useState(true);
  const notify = useNotification();

  const switchActiveTab = (tab: number) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setLoading(true);
    const type = activeTab === 0 ? 'new' : 'verified';
    fetchCheckHomeworks(type)
      .then((res) => setHomeWorks(res))
      .catch(() => notify.error('Произошла ошибка. Обратитесь в службу поддержки'))
      .finally(() => setLoading(false));
  }, [activeTab]);

  return (
    <div className="w-full mt-20 lg:mt-0">
      <div className="h-[calc(100vh-60px)] flex flex-col gap-y-10 pt-6 overflow-y-auto">
        <div className="flex flex-row justify-between items-center">
          <LMSTitle tag="h4" className="mt-auto text-white text-3xl">
            Проверка домашних заданий
          </LMSTitle>
        </div>

        <SwitchTabs
          leftTabName={'Новые'}
          rightTabName={'Проверенные'}
          activeTab={Number(activeTab)}
          onTabChange={(value) => switchActiveTab(value)}
        />

        <div className="w-full flex flex-col gap-y-4 pt-9">
          {isLoading && <Loader />}
          {!isLoading &&
            homeWorks &&
            homeWorks.length &&
            homeWorks?.map((el) => <CheckHomeWorkCard key={el.id} data={el} />)}
          {!isLoading && !homeWorks.length && (
            <div className="text-white text-center">Домашние задания отстуствуют</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckHomeWorkForTest;
