import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import AddNewLesson from 'modules/coach/components/AddNewLesson';

const NewLessons = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/coach/lessons');
  };

  return (
    <div className="w-full h-full flex flex-col mt-20 lg:mt-6">
      <button
        type="button"
        onClick={() => goBack()}
        className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-current text-[14px] font-medium bg-white bg-opacity-80 rounded-[36px] border-none outline-none uppercase"
      >
        <ArrowBack />
        назад
      </button>
      <div className="flex flex-col mt-10">
        <AddNewLesson />
      </div>
    </div>
  );
};

export default NewLessons;
