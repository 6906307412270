import { useCallback, useEffect, useState } from 'react';
import DropdownSelect from 'modules/coach/components/DropdownSelect';
import { useCoursesListData } from 'modules/hr/hooks/useCoursesListsData';

const JournalFilter = ({ filterParams }: { filterParams: CallableFunction }) => {
  const [selectedCourseId, setSelectedCourseId] = useState<{ id: number | null; name: string }>({
    id: null,
    name: '',
  });
  const [selectedGroupId, setSelectedGroupId] = useState<{ id: number | null; name: string }>({
    id: null,
    name: '',
  });
  const { coursesList, groups } = useCoursesListData(Number(selectedCourseId.id));

  const handleCourseClick = useCallback((id: number, name: string) => {
    setSelectedCourseId({ id, name });
  }, []);

  const handleGroupClick = useCallback((id: number, name: string) => {
    setSelectedGroupId({ id, name });
  }, []);

  const resetFilters = () => {
    setSelectedCourseId({ id: null, name: '' });
    setSelectedGroupId({ id: null, name: '' });
  };

  useEffect(() => {
    filterParams({
      courseId: selectedCourseId.id,
      groupId: selectedGroupId.id,
    });
  }, [selectedCourseId, selectedGroupId, filterParams]);

  return (
    <div className="relative w-full h-fit">
      <div className="w-full flex flex-col gap-2 md:flex-row">
        <div className="w-full md:w-fit md:max-w-[290px]">
          <DropdownSelect
            notRounded={false}
            title={'Курс'}
            value={selectedCourseId.name}
            list={coursesList}
            onCourseClick={handleCourseClick}
          />
        </div>
        <div className="w-full md:w-fit md:max-w-[290px]">
          <DropdownSelect
            notRounded={false}
            title={'Группа'}
            value={selectedGroupId.name}
            list={selectedCourseId.id ? groups : []}
            onCourseClick={handleGroupClick}
          />
        </div>
      </div>
      <button
        type="button"
        className="w-fit flex-row gap-x-2 mt-4 text-white md:flex"
        onClick={() => resetFilters()}
      >
        x <span className="underline underline-offset-4">Сбросить фильтры</span>
      </button>
    </div>
  );
};

export default JournalFilter;
