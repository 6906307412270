import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LMSButton from 'components/LMSButton';
import LMSPagination from 'components/LMSPagination';
import StudentTable from 'modules/hr/components/StudentsTable';

import AddUserByLinkModal from '../../components/AddUserByLinkModal';
import AddUserBySearchModal from '../../components/AddUserBySearchModal';
import AddUserModal from '../../components/AddUserModal';
import StudentFilter from '../../components/StudentFilter';
import { useStudents } from '../../hooks/students-list';

const HrStudents = () => {
  const navigate = useNavigate();
  const { isLoading, students, currentPage, lastPage, setCurrentPage } = useStudents();

  const [isShowAddUserModal, setIsShowAddUserModal] = useState<boolean>(false);
  const [isShowAddUserByLinkModal, setIsShowAddUserByLinkModal] = useState<boolean>(false);
  const [isShowAddUserBySearchModal, setIsShowAddUserBySearchModal] = useState<boolean>(false);

  return (
    <div className="flex flex-col items-start w-full py-4 lg:py-10">
      <div className="w-full flex flex-col lg:flex-row justify-between space-y-4 lg:space-y-0 lg:space-x-4 mb-6 lg:mb-10">
        <h2 className="text-[32px] font-semibold">База студентов</h2>
        <div className="flex flex-row items-center justify-between lg:justify-end space-x-4">
          <div className="hidden">
            <StudentFilter />
          </div>

          <LMSButton onClick={() => setIsShowAddUserModal(true)}>Добавить слушателя</LMSButton>
        </div>
      </div>

      <div className="w-full">
        {students && !isLoading && students.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <StudentTable students={students} />
            </div>

            <div className={lastPage > 1 ? 'mt-14' : ''}>
              <LMSPagination current={currentPage} lastPage={lastPage} onChange={setCurrentPage} />
            </div>
          </>
        )}

        {students.length === 0 && !isLoading && (
          <h3 className="mb-10 text-[24px] font-semibold text-center mt-10">
            Нет данных <br /> Попробуйте изменить фильтрацию
          </h3>
        )}

        {isShowAddUserModal ? (
          <AddUserModal
            onClose={() => setIsShowAddUserModal(false)}
            addUserManually={() => navigate('/hr/users/students/new-student')}
            openModalAddUserByLink={() => setIsShowAddUserByLinkModal(true)}
            openModalAddUserBySearch={() => setIsShowAddUserBySearchModal(true)}
          />
        ) : null}
        {isShowAddUserByLinkModal ? (
          <AddUserByLinkModal onClose={() => setIsShowAddUserByLinkModal(false)} />
        ) : null}
        {isShowAddUserBySearchModal ? (
          <AddUserBySearchModal onClose={() => setIsShowAddUserBySearchModal(false)} />
        ) : null}
      </div>
    </div>
  );
};

export default HrStudents;
