import request from 'api/axios-instance';
import { Option } from 'entities/Option';

import { Coach } from '../../entities/Coaches';
import { UserDetail } from '../../entities/User';
import { CoachDao } from '../dao/coaches';
import { UserDetailDao } from '../dao/user.dao';

export const fetchFilterCourses = (params?: Record<string, string>) =>
  request.get('v1/courses-filter', { params }).then((res) => {
    const courses: Option[] = res.data.map(mapCoachesDaoToEntity);
    return courses;
  });

export const fetchCoaches = (params: Record<string, string | number | null>) =>
  request.get('v1/coaches', { params }).then((res) => {
    const coaches: Coach[] = res?.data?.data?.map(mapCoachesDaoToEntity) || [];
    return { coaches, lastPage: (res?.data?.meta?.last_page as number) || 1 };
  });

export const fetchCoach = (id: number) =>
  request.get(`v1/coaches/${id}`).then((res) => coachDaoToEntity(res?.data.data));

function mapCoachesDaoToEntity(coach: CoachDao): Coach {
  return {
    id: coach.id,
    email: coach.email,
    fullName: coach.full_name,
    login: coach.login,
    phoneNumber: coach.phone_number,
    photo: coach.photo,
    specialization: coach.specialization,
    type: coach.type,
  };
}

function coachDaoToEntity(coach: UserDetailDao): UserDetail {
  return {
    id: coach.id,
    email: coach.email,
    fullName: coach.full_name,
    login: coach.login,
    personalPhoneNumber: coach.personal_phone_number,
    jobPhoneNumber: coach.job_phone_number,
    photo: coach.photo,
    specialization: coach.specialization,
    type: coach.type,
    salary: coach.salary,
    status: coach.status,
    languages: coach.languages,
    scoring: coach.scoring,
    position: coach.position,
    iin: coach.iin,
    resume: coach.resume,
    requisites: coach.requisites,
    scoringFile: coach.scoring_file,
    document: coach.document,
    agreementFiles: coach.agreement_files,
    course: coach.course,
    module: coach.module,
  };
}
