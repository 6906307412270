import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { fetchNotificationsCount } from '../modules/student/api/repository/notifications';
import { notificationsSlice } from '../store/notifications';

const StudentLayout = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const [query, setQuery] = useSearchParams();

  const THEME = query.get('theme') === 'light';
  const isTestIT =
    window.location.href.includes('test-it') || window.location.href.includes('cte-platform');

  const isLight = isTestIT || THEME;

  useEffect(() => {
    if (user) {
      fetchNotificationsCount().then((res) => {
        dispatch(notificationsSlice.actions.setNotificationsCount(res));
      });
    }
  }, [user]);

  if (!user) {
    return <Navigate to="/auth/login" />;
  }

  if (user.currentRole === 'coach') {
    return <Navigate to="/coach/schedule" />;
  }

  if (user.currentRole === 'hr') {
    return <Navigate to="/hr" />;
  }

  return (
    <div
      className={`w-full min-h-[100dvh] flex flex-col items-center pb-20 theme ${
        isLight && 'light-theme'
      }`}
    >
      <Outlet />
    </div>
  );
};

export default StudentLayout;
