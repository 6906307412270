import React, { useState } from 'react';
import { ReactComponent as CopyIcon } from 'assets/icons/document-copy.svg';
import BaseModal from 'components/BaseModal';
import LMSTitle from 'components/LMSTitle';

import DropdownSelect from '../DropdownSelect';

interface Props {
  onClose: () => void;
}

const AddUserByLinkModal: React.FC<Props> = ({ onClose }) => {
  const [group, setGroup] = useState<string>('');

  return (
    <BaseModal wrapperClassName="sm:!max-w-max" onClose={onClose}>
      <div className="flex flex-col space-y-6 min-w-full lg:min-w-[436px] max-w-[436px] p-6 lg:p-10">
        <LMSTitle tag="h2">Доступ по ссылке</LMSTitle>
        <div className="flex flex-col space-y-4">
          <DropdownSelect
            notRounded={true}
            title="Добавить в группу"
            value={group}
            list={[{ value: 1, label: 'Test' }]}
            onCourseClick={(id, name) => setGroup(name)}
            className="bg-[#F2F2F2] border-none !text-black"
          />
          <div className="flex flex-col space-y-2">
            <p>Скопируйте ссылку и отправьте пользователю, которого хотите добавить</p>
            <div className="flex flex-row items-center space-x-2 w-full rounded-[14px] bg-[#F2F2F2] pr-6">
              <input
                type="text"
                placeholder="cteplatform.kz/link2134758215/adduser"
                disabled
                className="w-full h-[50px] py-3 px-6 text-white bg-transparent rounded-[14px] outline-none"
              />
              <button onClick={() => {}}>
                <CopyIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default AddUserByLinkModal;
