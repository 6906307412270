import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/add-blue.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import LMSButton from 'components/LMSButton';
import LMSTitle from 'components/LMSTitle';
import ru from 'date-fns/locale/ru';
import { createCourse } from 'modules/hr/api/repository/create-course';
import { INewCourseCoach } from 'modules/hr/entities/Course';
import { useNotification } from 'hooks/notification';
import { ErrorResponse } from 'entities/Error';
import { Option } from 'entities/Option';

import { fetchStudyTypes } from '../../api/repository/handbooks';
import { currencies, currencyShortNames } from '../../constants';
import DropdownSelect from '../DropdownSelect';

const AddNewCourse = ({ coaches }: { coaches: Option[] }) => {
  const { control, handleSubmit, setValue, watch } = useForm<Record<string, string | number>>();
  const notify = useNotification();
  const navigate = useNavigate();

  const [image, setImage] = useState<File | null>();
  const [imagePreview, setImagePreview] = useState<string>();
  const [studyType, setStudyType] = useState<string>();
  const [hasCertificate, setHasCertificate] = useState<number>();
  const [studyTypes, setStudyTypes] = useState<Option[]>([]);
  const [currency, setCurrency] = useState<string>();
  const [selectedCoaches, setSelectedCoaches] = useState<Option[]>([]);
  const [minDate, setMinDate] = useState<string>('');
  const [maxDate, setMaxDate] = useState<string>('');

  const formatDate = (date: Date) => {
    const year = date.getFullYear().toString().padStart(4, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const onChangeSelectedCoaches = (id: number, name: string) => {
    if (selectedCoaches.some((selectedCoach) => selectedCoach.value === id)) {
      setSelectedCoaches((prevState) =>
        prevState.filter((selectedCoach) => selectedCoach.value !== id)
      );
    } else {
      setSelectedCoaches((prevState) => [...prevState, { value: id, label: name }]);
    }
    setValue(
      'coaches',
      selectedCoaches.reduce((acc, curr) => {
        if (acc) {
          return acc + `, ${curr.label}`;
        }
        return acc + curr.label;
      }, '') as string
    );
  };

  const onSubmit = async (formData: Record<string, string | number>) => {
    try {
      const params: Record<string, string | number | INewCourseCoach[]> = {
        ...formData,
        image_url: '',
        has_certificate: formData.has_certificate ? '1' : '0',
        coaches: selectedCoaches.map((coach) => ({ coach_id: coach.value })),
      };

      await createCourse(params);
      notify.success('Курс успешно создан');
      setTimeout(() => {
        navigate('/hr/courses');
      }, 600);
    } catch (err) {
      const error = err as ErrorResponse;
      console.log(error, 'error');
      notify.error('Не удалось создать курс, повторите еще раз или обратитесь в службу поддержки');
    }
  };

  useEffect(() => {
    fetchStudyTypes().then((data) => {
      setStudyTypes(data);
    });
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'date_from') {
        setMinDate(value.date_from ? value.date_from.toString() : '');
      }
      if (name === 'date_to') {
        setMaxDate(value.date_to ? value.date_to.toString() : '');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-end">
        <LMSTitle tag="h4" className="text-white text-3xl">
          Создание курса
        </LMSTitle>
      </div>

      <div className="w-full h-full flex flex-col gap-y-5 mt-10 mb-20 sm:mb-0">
        <div className="w-full flex flex-col gap-10 p-5 bg-gradient rounded-3xl sm:p-10">
          <div className="flex flex-col">
            <p className="text-white text-2xl mb-5">Основные данные</p>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-4">
              <Controller
                name="image_url"
                control={control}
                rules={{
                  required: { message: 'Добавьте обложку курса', value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="row-span-3">
                    <div
                      className={`flex w-full h-full max-h-[219px] text-white bg-[#070b20] border border-0.5 border-dashed rounded-[14px] overflow-hidden outline-none ${
                        error ? 'border-red-500' : 'border-blue'
                      }`}
                    >
                      <label htmlFor="image" className="flex flex-1 cursor-pointer">
                        {imagePreview ? (
                          <img
                            src={imagePreview}
                            alt="preview"
                            className="w-full h-full object-cover object-center"
                          />
                        ) : (
                          <div className="flex flex-col flex-1 items-center justify-center py-3 px-6">
                            <PlusIcon className="mb-4" />
                            <p>Добавить обложку курса</p>
                          </div>
                        )}
                      </label>
                      <input
                        id="image"
                        type="file"
                        accept=".jpg, .png"
                        {...field}
                        className="hidden"
                        onChange={(event) => {
                          const target = event.target as HTMLInputElement;
                          if (target.files && target.files.length) {
                            setImagePreview(URL.createObjectURL(target.files[0]));
                            setImage(target.files[0]);
                            field.onChange(event);
                          }
                        }}
                      />
                    </div>
                    {error && <p className="text-red-500 mt-2">{error.message}</p>}{' '}
                  </div>
                )}
              />
              <p className="lg:hidden text-sm text-[#ACAFC9] text-center">
                Формат: JPG, PNG
                <br />
                Приемлемое разрешение: 1280x720
              </p>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: { message: 'Введите название курса', value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <input
                      type="text"
                      placeholder="Название курса"
                      {...field}
                      className={`w-full h-[50px] py-3 px-6 text-white bg-transparent border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                        error ? 'border-red-500' : 'border-white'
                      }`}
                    />
                    {error && <p className="text-red-500 mt-2">{error.message}</p>}{' '}
                  </div>
                )}
              />
              <Controller
                name="description"
                control={control}
                rules={{
                  required: { message: 'Введите описание курса', value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="row-span-2 w-full rounded-[14px]">
                    <textarea
                      {...field}
                      className={`w-full h-[142px] py-3 px-6 text-white bg-transparent border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                        error ? 'border-red-500' : 'border-white'
                      }`}
                      placeholder="Описание курса"
                    ></textarea>
                    {error && <p className="text-red-500 mt-2">{error.message}</p>}{' '}
                  </div>
                )}
              />
            </div>

            <div className="hidden lg:grid grid-cols-2 gap-6">
              <p className="text-sm text-[#ACAFC9] text-center">
                Формат: JPG, PNG
                <br />
                Приемлемое разрешение: 1280x720
              </p>
            </div>
          </div>

          <div className="flex flex-col">
            <p className="text-white text-2xl mb-5">Дополнительные данные</p>

            <div className="grid grid-cols-1 lg:grid-cols-2 grid-rows-2 gap-6">
              <div className="w-full flex flex-row new-lesson">
                <Controller
                  name="date_from"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value ? new Date(field.value) : null}
                      locale={ru}
                      placeholderText="Дата начала"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={maxDate ? new Date(maxDate) : undefined}
                      dateFormat="yyyy.MM.dd"
                      onChange={(date) =>
                        setValue('date_from', date ? formatDate(date as Date) : '')
                      }
                    />
                  )}
                />
                <CalendarIcon className="ml-[-44px] mt-[14px] text-white" />
              </div>
              <div className="w-full flex flex-row new-lesson">
                <Controller
                  name="date_to"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value ? new Date(field.value) : null}
                      locale={ru}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Дата завершения"
                      minDate={minDate ? new Date(minDate) : undefined}
                      dateFormat="yyyy.MM.dd"
                      onChange={(date) => setValue('date_to', date ? formatDate(date as Date) : '')}
                    />
                  )}
                />
                <CalendarIcon className="ml-[-44px] mt-[14px] text-white" />
              </div>
              <Controller
                name="study_type"
                control={control}
                rules={{
                  required: { message: 'Выберите тип обучения', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    title="Тип обучения"
                    value={studyType}
                    list={studyTypes}
                    error={error?.message}
                    onCourseClick={(id, name) => {
                      setValue('study_type', id);
                      setStudyType(name);
                    }}
                  />
                )}
              />
              <Controller
                name="has_certificate"
                control={control}
                rules={{
                  required: { message: 'Выберите наличие сертификата', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    title="Наличие сертификата"
                    value={hasCertificate === 1 ? 'Да' : hasCertificate === 0 ? 'Нет' : ''}
                    list={[
                      {
                        value: 1,
                        label: 'Да',
                      },
                      {
                        value: 0,
                        label: 'Нет',
                      },
                    ]}
                    error={error?.message}
                    onCourseClick={(id) => {
                      setValue('has_certificate', id);
                      setHasCertificate(id);
                    }}
                  />
                )}
              />
              <Controller
                name="price"
                control={control}
                rules={{
                  required: { message: 'Введите стоимость курса', value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <input
                      type="text"
                      placeholder="Стоимость курса"
                      {...field}
                      className={`w-full h-[50px] py-3 px-6 text-white bg-transparent border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                        error ? 'border-red-500' : 'border-white'
                      }`}
                    />
                    {error && <p className="text-red-500 mt-2">{error.message}</p>}{' '}
                  </div>
                )}
              />
              <Controller
                name="currency"
                control={control}
                rules={{
                  required: { message: 'Выберите валюту', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    title="Валюта"
                    value={currency}
                    list={currencies}
                    error={error?.message}
                    onCourseClick={(id, name) => {
                      setValue('currency', currencyShortNames[id]);
                      setCurrency(name);
                    }}
                  />
                )}
              />
              <Controller
                name="duration_in_hours"
                control={control}
                rules={{
                  required: { message: 'Введите длительность курса (в часах)', value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <input
                      type="text"
                      placeholder="Длительность курса (в часах)"
                      {...field}
                      className={`w-full h-[50px] py-3 px-6 text-white bg-transparent border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                        error ? 'border-red-500' : 'border-white'
                      }`}
                    />
                    {error && <p className="text-red-500 mt-2">{error.message}</p>}{' '}
                  </div>
                )}
              />
              <Controller
                name="coaches"
                control={control}
                rules={{
                  required: { message: 'Выберите тренера', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    title="Тренеры"
                    value={selectedCoaches.reduce((acc, curr) => {
                      if (acc) {
                        return acc + `, ${curr.label}`;
                      }
                      return acc + curr.label;
                    }, '')}
                    list={coaches}
                    error={error?.message}
                    onCourseClick={(id, name) => onChangeSelectedCoaches(id, name)}
                  />
                )}
              />
            </div>
          </div>

          <LMSButton
            onClick={handleSubmit(onSubmit)}
            className="fixed bottom-0 left-0 right-0 py-4 rounded-none self-end sm:static sm:rounded-full"
          >
            СОЗДАТЬ КУРС
          </LMSButton>
        </div>
      </div>
    </div>
  );
};

export default AddNewCourse;
