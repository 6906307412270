import request from 'api/axios-instance';

import { CoachTest } from '../../entities/Test';
import { CoachCurrentTestDao } from '../dao/Test.dao';

export const fetchCoachTest = (id: string) =>
  request.get(`v1/tests/${id}`).then((res) => {
    const test = res.data.data;
    return test;
  });

export const updateCoachTest = (id: string, data: TestDto) => {
  return request.post(`v1/coach-tests/${id}`, data).then((res) => {
    const data = res.data.data;
    return data;
  });
};

export const deleteCoachTest = (id: string) => {
  return request.delete(`v1/coach-tests/${id}`).then((res) => {
    const data = res.data.data;
    return data;
  });
};

interface TestDto {
  id?: number;
  deadline: string;
  is_last: boolean;
  time_amount: number;
  attempts_number: number;
  name?: string;
  minimum_score_to_pass?: number;
  lesson_id?: number;
  lesson_name?: string;
  questions: {
    name: string;
    answers: {
      name: string;
      is_correct: boolean;
    }[];
  }[];
}

export const createCoachTests = (data: TestDto) => request.post('v1/coach-tests', data);
