import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Avatar from 'components/Avatar';

interface IStudents {
  id: number;
  avatar: string;
  name: string;
  email: string;
  wasOnline: string;
}

type Props = {
  students: IStudents[];
};

const Users: FC<Props> = (props) => {
  const navigate = useNavigate();

  const { id } = useParams();

  const redirectToCourse = (studentId: number) => {
    navigate(`/coach/courses/${id}/student/${studentId}`);
  };

  return (
    <div className="w-full h-full flex flex-col py-6 px-10 bg-[#0c1231] rounded-tr-[20px] rounded-br-[20px] rounded-bl-[20px]">
      <table className="table-auto w-full">
        <thead>
          <tr className="h-12 text-white text-sm font-normal text-opacity-40">
            <th className="w-[5%] text-left">№</th>
            <th className="w-[30%] text-left">Студент</th>
            <th className="w-[30%] text-left">Email</th>
            <th className="w-[20%] text-left">Был онлайн</th>
            <th className="w-[20%] text-left">Посмотреть статистику</th>
          </tr>
        </thead>
        <tbody className="w-full">
          {props.students &&
            props.students.map((el) => (
              <tr key={el.id} className="w-full text-white text-base font-normal tracking-wide">
                <td className="h-16 text-white text-lg font-medium">{el.id + 1}</td>
                <td className="h-16 flex flex-row items-center gap-x-5">
                  <div className="w-10 h-10">
                    <Avatar />
                  </div>
                  {el.name}
                </td>
                <td className="h-16">{el.email}</td>
                <td className="h-16">{el.wasOnline}</td>
                <td className="h-16">
                  <button
                    onClick={() => redirectToCourse(el.id)}
                    className="px-6 py-2 text-base text-white font-semibold rounded-full bg-white bg-opacity-20 cursor-pointer"
                  >
                    Перейти
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Users;
